import { FacebookPixelService } from './../../../shared/utils/facebook-pixel.service';
import { FaqService } from './../faq.service';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  contactElements: any = [
    {
      title: {
        el: 'Όνομα',
        en: 'Name',
      },
      control: 'name',
      class: 'col-5 ',
    },
    {
      title: {
        el: 'Email',
        en: 'Email',
      },
      control: 'email',
      class: 'col-5',
    },
    {
      title: {
        el: 'Θέμα',
        en: 'Subject',
      },
      control: 'subject',
      class: 'w-100',
    },
    {
      title: {
        el: 'Μήνυμα',
        en: 'Message',
      },
      control: 'message',
      class: 'w-100',
    },
  ];
  lang: string = this.TranslateService.currentLang;
  contactForm!: UntypedFormGroup;
  captchaKey = environment.RECAPTCHA_SITE_KEY;
  constructor(
    private TranslateService: TranslateService,
    private FormBuilder: UntypedFormBuilder,
    private FaqService: FaqService,
    private FacebookPixelService: FacebookPixelService
  ) {
    this.TranslateService.onLangChange.subscribe((change: any) => {
      this.lang = change.lang;
    });
  }

  ngOnInit(): void {
    this.contactForm = this.FormBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      recaptcha: [null, [Validators.required]],
    });
  }
  onSubmit(form: UntypedFormGroup) {
    this.FacebookPixelService.contactForm({
      content_category: 'contact',
      content_name: 'contact form',
    });
    this.FaqService.sendContactEmail(form.value).subscribe((res: any) => {
      if (res) {
        window.alert(res.message);
        this.contactForm.reset();
      }
    });
  }
  resolved(data: any) {
    this.contactForm.patchValue({
      recaptcha: data,
    });
  }
}
