import { map, tap } from 'rxjs/operators';
import { PagesService } from './../../../pages/pages.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { BrowserService } from './browser.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject!: BehaviorSubject<User | null>;
  public currentUser!: Observable<User | null>;

  constructor(
    private pService: PagesService,
    private BrowserService: BrowserService
  ) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }
  initiateUser(user: any) {
    this.currentUserSubject.next(user);
  }

  login(body: any) {
    return this.pService.post('auth/login', body).pipe(
      map((user: any) => {
        this.BrowserService.setLocalStorage(
          'currentUser',
          JSON.stringify(user)
        );
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    this.BrowserService.removeLocalStorage('currentUser');
    this.BrowserService.removeLocalStorage('myReservations');
    this.currentUserSubject.next(null);
  }
  register(body: any) {
    return this.pService.post('users', body);
  }
}
