export const formFilterData = (filters: any) => {
  filters.pickupDate = `${filters.pickupDate.getDate()}-${
    filters.pickupDate.getMonth() + 1
  }-${filters.pickupDate.getFullYear()}`;
  filters.returnDate = `${filters.returnDate.getDate()}-${
    filters.returnDate.getMonth() + 1
  }-${filters.returnDate.getFullYear()}`;

  return {
    pickupTime: filters.pickupTime,
    returnTime: filters.returnTime,
    returnDate: filters.returnDate,
    pickupDate: filters.pickupDate,
    pickupLocation: filters.pickupLocation.code,
    returnLocation: filters.pickupLocation.code,
  };
};
