<div
  class=""
  [ngClass]="additional === true ? 'additionalContainer' : 'myContainer'"
>
  <ng-container *ngIf="timer$ | async"></ng-container>
  <div class="img-cont">
    <img
      *ngIf="!stepper"
      class="img-photo"
      src="assets/images/filter.jpg"
      alt=""
    />

    <div
      *ngIf="!stepper && showTravelAndBookReservationTitle"
      class="filter-banner d-flex"
    >
      <div class="bg-black travel text-white d-flex text-nowrap">
        <span>{{ "frontPage.filtering.title.travel" | translate }}</span>
      </div>
      <div class="bg-red reservation text-white d-flex text-nowrap">
        <span>{{ "frontPage.filtering.title.reservation" | translate }}</span>
      </div>
    </div>
    <div class="" [ngClass]="stepper === true ? 'tabset-selected' : 'tabset'">
      <ul *ngIf="!stepper" class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            (click)="tabChangeToGreece()"
          >
            {{ "frontPage.filtering.tabs.header1" | translate }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            (click)="tabChangeToForeign()"
          >
            {{ "frontPage.filtering.tabs.header2" | translate }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade {{ greekTab }} bg-white"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="myContainer">
            <div *ngIf="!stepper" class="mt-3 mb-3">
              <span>{{ "frontPage.filtering.body.title" | translate }}</span>
            </div>
            <div *ngIf="stepper" style="font-size: 1.5rem; padding: 1rem">
              <span style="font-weight: 600">{{
                "frontPage.filtering.body.contact" | translate
              }}</span>
              {{ "frontPage.filtering.body.info" | translate }}
            </div>
            <form [formGroup]="searchForm" action="" class="d-flex flex-column">
              <!-- <div class="d-flex justify-content-between flex-column flex-md-row"> -->
              <div class="row">
                <!-- PICKUP LOCATION -->
                <div
                  class="input col-12"
                  [ngClass]="stepper ? 'col-lg-12' : 'col-md-4 '"
                >
                  <mat-form-field appearance="outline">
                    <mat-label
                      >{{
                        "frontPage.filtering.body.pickUpLocation" | translate
                      }}
                    </mat-label>
                    <!-- <mat-label *ngIf="stepper">{{
                      "frontPage.filtering.body.pickUpLocation1" | translate
                    }}</mat-label> -->
                    <mat-select
                      [compareWith]="compare"
                      [formControlName]="'pickupLocation'"
                    >
                      <mat-form-field class="example-full-width p-2 px-3">
                        <input
                          (keyup)="search($event.target)"
                          matInput
                          placeholder="Αναζήτηση"
                          value=""
                        />
                      </mat-form-field>
                      <ng-container
                        *ngIf="locations$ | async as locationsArray"
                      >
                        <mat-option
                          *ngFor="let item of locationsArray"
                          [value]="item"
                          >{{ item.name[lang] }}</mat-option
                        >
                      </ng-container>
                    </mat-select>

                    <mat-icon matSuffix>location_on</mat-icon>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="stepper"
                  class="dash"
                  style="
                    width: 100%;
                    border-top: 2px dashed;
                    border-color: #707070;
                  "
                ></div>
                <!-- PICKUP DATE -->
                <div
                  class="input col-12"
                  [ngClass]="stepper ? 'col-lg-6' : 'col-md-4 order-1'"
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "frontPage.filtering.body.pickUpDate" | translate
                    }}</mat-label>
                    <input
                      [formControlName]="'pickupDate'"
                      matInput
                      [matDatepicker]="picker"
                      [matDatepickerFilter]="datePickerFilterPickUp"
                      (click)="picker.opened = !picker.opened"
                      readonly="readonly"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <!-- <mat-icon matSuffix>calendar_today</mat-icon> -->
                  </mat-form-field>
                </div>
                <!-- PICKUP TIME -->
                <div
                  class="input col-12"
                  [ngClass]="stepper ? 'col-lg-6 order-2' : 'col-md-4 order-1 '"
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "frontPage.filtering.body.pickUpTime" | translate
                    }}</mat-label>
                    <mat-select
                      [formControlName]="'pickupTime'"
                      [compareWith]="compare1"
                    >
                      <mat-option
                        *ngFor="let item of timetable"
                        [value]="item"
                        >{{ item }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- RETURN DATE -->
                <div
                  class="input col-12"
                  [ngClass]="stepper ? 'col-lg-6 order-1' : 'col-md-4 order-3'"
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "frontPage.filtering.body.dropOffDate" | translate
                    }}</mat-label>
                    <input
                      [formControlName]="'returnDate'"
                      matInput
                      [matDatepicker]="picker1"
                      [matDatepickerFilter]="datePickerFilterDropOff"
                      (click)="picker1.opened = !picker1.opened"
                      readonly="readonly"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <!-- RETURN TIME -->
                <div
                  class="input col-12"
                  [ngClass]="stepper ? 'col-lg-6 order-3' : 'col-md-4 order-3 '"
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "frontPage.filtering.body.dropOffTime" | translate
                    }}</mat-label>
                    <mat-select
                      [formControlName]="'returnTime'"
                      [compareWith]="compare1"
                    >
                      <mat-option
                        *ngFor="let item of timetable"
                        [value]="item"
                        >{{ item }}</mat-option
                      >
                    </mat-select>
                    <!-- <input [formControlName]="'returnTime'"  matInput placeholder="Placeholder"> -->
                    <!-- <mat-icon matSuffix>keyboard_arrow_down</mat-icon> -->
                  </mat-form-field>
                </div>
                <!-- AGE CHECK -->
                <div
                  class="age d-flex col-12 col-md-4 mb-4 order-last order-md-2"
                  *ngIf="!stepper"
                >
                  <mat-checkbox
                    class="checkbox"
                    [checked]="!stepper ? ageSelected : true"
                    (change)="ageSelected = !ageSelected"
                  >
                    {{ "frontPage.filtering.body.checkBox" | translate }}
                  </mat-checkbox>
                </div>
              </div>
            </form>

            <div
              class="bg-red text-white mb-3"
              style="width: 100%; padding: 1rem"
              [ngClass]="enablePromt ? 'd-block' : 'd-none'"
            >
              <div *ngIf="!ageSelected">
                {{ "frontPage.filtering.body.notCheckbox" | translate }}
              </div>
              <div *ngIf="!locationSelected">
                {{ "frontPage.filtering.body.noLocation" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="!stepper"
          class="tab-pane fade bg-white {{ foreignTab }}"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <iframe class="frame" [src]="iframeUrl[lang]"></iframe>
        </div>
      </div>

      <div class="w-100 bg-red red-strip"></div>
      <button
        mat-flat-button
        (click)="onSubmit(searchForm.value)"
        class="bg-red price-check text-black"
      >
        {{ "frontPage.filtering.body.button" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="!stepper" class="offers d-flex flex-column">
    <span class="text-red drive-caps">{{
      "frontPage.filtering.body.offers" | translate
    }}</span
    ><span class="discover">{{
      "frontPage.filtering.body.subtitle" | translate
    }}</span>
  </div>
</div>
