<div class="marginTop">
  <app-filtering
    [additional]="false"
    [ageSelected]="true"
    [searchForm]="searchForm"
  ></app-filtering>
  <ng-container *ngIf="topOffer$ | async as top">
    <app-front-reusable-offer
      [imageUrl]="top.images[0].filename"
      [title]="top.title"
      [subtitle]="top.subtitle"
      [description]="top.description"
      [url]="top.url"
      [textOrientation]="'ps-5'"
    ></app-front-reusable-offer>
  </ng-container>
  <ng-container *ngIf="bottomOffer$ | async as bottom">
    <app-front-reusable-offer
      *ngIf="bottom"
      [imageUrl]="bottom.images[0].filename"
      [title]="bottom.title"
      [subtitle]="bottom.subtitle"
      [description]="bottom.description"
      [url]="bottom.url"
      [rotation]="'flex-row-reverse'"
      [textOrientation]="'pe-5 align-items-end pt-5'"
    ></app-front-reusable-offer>
  </ng-container>
  <app-about-us [lang]="lang"></app-about-us>
</div>
