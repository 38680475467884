import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LeasingService } from '../../leasing/leasing.service';
import { ModalService } from 'src/app/core/core/services/modal.service';
import { LANG } from 'src/app/shared/interface/lang';

@Component({
  selector: 'app-be-a-partner',
  templateUrl: './be-a-partner.component.html',
  styleUrls: ['./be-a-partner.component.css'],
})
export class BeAPartnerComponent implements OnInit {
  partnerForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.email, Validators.required]],
    phone: ['', Validators.minLength(10)],
    recaptcha: [null, Validators.required],
    job: ['', Validators.required],
    areaOfInterest: ['', Validators.required],
    comments: ['', Validators.required],
  });
  captchaKey = environment.RECAPTCHA_SITE_KEY;
  lang: LANG = 'el';
  firstColumn: any = [
    {
      class: 'inpuLong',
      label: {
        el: 'Επωνυμία Εταιρίας/ Επαγγελματία',
        en: 'Fullname',
      },
      placeholder: 'K**** T****',
      control: 'name',
    },
    {
      class: 'inputShort',
      label: { el: 'Τηλέφωνο', en: 'Phone number' },
      placeholder: '+306*********',
      control: 'phone',
    },
    {
      class: 'inpuLong',
      label: { el: 'Επάγγελμα', en: 'Job' },
      placeholder: 'Engineer',
      control: 'job',
    },
  ];
  secondColumn: any = [
    {
      class: 'inputShort',
      label: { el: 'E-mail', en: 'E-mail' },
      placeholder: 'info@drivehellas.gr',
      control: 'email',
    },
    {
      class: 'inputShort',
      label: { el: 'Περιοχή ενδιαφέροντος', en: 'Area of interest' },
      placeholder: 'Thessaloniki',
      control: 'areaOfInterest',
    },
  ];
  thirdColumnEquipment: any = {
    class: 'inpuLong',
    label: {
      el: 'Σχόλια',
      en: 'Comments',
    },
    placeholder: 'Θα ήθελα *** ',
    control: 'comments',
  };
  title: { en: string; el: string } = {
    en: 'Be a partner - Expression of Interest',
    el: 'Be a partner - Αίτηση Ενδιαφέροντος',
  };
  description: { en: string; el: string } = {
    en: '<p>Are you in the car rental business and looking for a dynamic brand to expand yourcompany&#39;s operations?</p> <p>Join the Drive team and become part of our partner network!</p><p>With 30 years of experience, where quality is inseparably linked to competitiveness,we promise nothing less than excellent and safe services.</p><p>By collaborating with the largest international car rental agencies, we lead the globaltrend for flexible mobility solutions, and we encourage you to partner with us.</p><p>Fill out the form below, and let’s explore the possibility of a long-term and fruitfulcollaboration together.</p>',
    el: '<p>Είσαι στο χώρο των ενοικιάσεων αυτοκινήτων και αναζητάς ένα δυναμικό Brand για ναεπεκτείνεις τη δραστηριότητα της επιχείρησής σου;</p><p>Έλα στην ομάδα της Drive και γίνε μέλος στο δίκτυο των συνεργατών μας!</p><p>Έχοντας άρρηκτα συνδεδεμένη την ποιότητα με την ανταγωνιστικότητα στη φιλοσοφία τουομίλου, στα 30 χρόνια λειτουργίας μας, δεν θα μπορούσαμε να υποσχεθούμε τίποταλιγότερο από άριστες και ασφαλείς υπηρεσίες.</p><p>Έτσι, σε συνεργασία με τα μεγαλύτερα διεθνή πρακτορεία ενοικιάσεων αυτοκινήτων πρωταγωνιστούμε στη παγκόσμια τάση για ευέλικτες λύσεις μετακίνησης και σεπροτρέπουμε να συνεργαστείς μαζί μας.</p><p>Συμπλήρωσε τα στοιχεία σου στην παρακάτω φόρμα και ας εξετάσουμε μαζί το ενδεχόμενο μιας μακρόχρονης και αποδοτικής συνεργασίας.</p>',
  };

  constructor(
    private fb: FormBuilder,
    private lService: LeasingService,
    private ModalService: ModalService,
    private TranslateService: TranslateService
  ) {
    this.lang = this.TranslateService.currentLang as LANG;
    this.TranslateService.onLangChange.subscribe((change) => {
      this.lang = change.lang as LANG;
    });
  }

  ngOnInit(): void {}

  resolved(event: any) {
    this.partnerForm.patchValue({
      recaptcha: event,
    });
  }
  onSubmit(value: any) {
    let body = {
      Email: value.email,
      form: 'be-a-partner',
      recaptcha: value.recaptcha,
      'Επωνυμία Εταιρίας/ Επαγγελματία': value.name,
      'Τηλέφωνο επικοινωνίας': value.phone,
      Επάγγελμα: value.job,
      'Περιοχή ενδιαφέροντος': value.areaOfInterest,
      Σχόλια: value.comments,
    };
    this.lService.beApartnerForm(body).subscribe((res: any) => {
      if (res) {
        this.ModalService.openDialog();
      }
    });
  }
}
