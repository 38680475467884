import { PagesService } from './../pages.service';
import { CarService } from './car.service';
import { SelectedCarService } from './selected-car.service';
import { CouponService } from './coupon.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Order } from 'src/app/core/core/models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private order = new BehaviorSubject<Order | null>(null);
  private order$ = this.order.asObservable();
  // .pipe(tap((res) => console.log('####', res)));

  constructor(
    private CouponService: CouponService,
    private SelectedCarService: SelectedCarService,
    private PagesService: PagesService
  ) {}

  initFromStorage() {
    this.order.next(this.getFromStorage());
  }
  getOrder() {
    return this.order$;
  }
  getTotalForOnlinePayment() {
    const currentOrder = this.getFromStorage();
    if (!currentOrder) return 0;
    return (
      currentOrder.coupon
        ? currentOrder.onlineTotalSumWithDiscount
        : (currentOrder.car?.price || 0) - (currentOrder.outOfHours || 0)
    ).toFixed(2);
  }
  updateOnlineSum() {
    let currentOrder: Order | null = this.getFromStorage();
    let onlineTotalSumWithDiscount;
    // console.log('coupon update sum', currentOrder);
    if (!currentOrder) return;
    if (!currentOrder.coupon) return;
    let carPrice =
      (currentOrder.car?.price || 0) - (currentOrder.outOfHours || 0);
    if (currentOrder.coupon?.type === 'percentage') {
      let discount = (carPrice * currentOrder.coupon.value) / 100;
      // console.log(carPrice, discount);

      onlineTotalSumWithDiscount = (carPrice - discount).toFixed(2);
    } else if (currentOrder.coupon?.type === 'flat') {
      onlineTotalSumWithDiscount = (
        carPrice - currentOrder.coupon.value
      ).toFixed(2);
    } else {
      onlineTotalSumWithDiscount = carPrice.toFixed(2);
    }
    this.setToStorage({
      ...currentOrder,
      onlineTotalSumWithDiscount: Number(onlineTotalSumWithDiscount),
    });
    // this.order.next({
    //   ...currentOrder,
    //   onlineTotalSumWithDiscount: Number(onlineTotalSumWithDiscount),
    // });
    // console.log('online sum', this.order.value);
  }

  updateOrder(reservation: any) {
    // console.log('@@@', reservation.request);
    const selectedCar = this.SelectedCarService.getFromStorage();
    const order = this.tempReservationToOrderHelper(reservation, selectedCar);
    this.setToStorage(order);
    // this.order.next(order);
    // console.log(this.order.value);
  }
  updateInsurance(insurance: any) {
    let currentOrder: Order | null = this.getFromStorage();
    if (!currentOrder) return;
    currentOrder = {
      ...currentOrder,
      insuranceCost: insurance.price * insurance.days || 0,
    };
    this.setToStorage(currentOrder);
    // this.order.next(currentOrder);
    // console.log(this.order.value);
  }
  updateStation(station: any) {
    // console.log(station);
    let currentOrder: Order | null = this.getFromStorage();
    if (!currentOrder) currentOrder = {};
    currentOrder = {
      ...currentOrder,
      station,
    };
    this.setToStorage(currentOrder);
    // this.order.next(currentOrder);
    // console.log(this.order.value);
  }
  updateEquipment(equipmentCost: any) {
    let currentOrder: Order | null = this.getFromStorage();
    // const orderid = this.generateOrderId();
    if (!currentOrder) currentOrder = {};
    currentOrder = {
      ...currentOrder,
      equipmentCost,
    };
    // this.order.next(currentOrder);
    this.setToStorage(currentOrder);
  }
  updateRentDuration(days: number) {
    let currentOrder: Order | null = this.getFromStorage();
    if (!currentOrder) currentOrder = {};
    currentOrder = {
      ...currentOrder,
      duration: days,
    };
    // this.order.next(currentOrder);
    this.setToStorage(currentOrder);
    this.updateOnlineSum();
  }
  updateOutOfHours(outOfHours: number) {
    // console.log(outOfHours);
    let currentOrder = this.getFromStorage();
    const orderid = this.generateOrderId();

    currentOrder = { ...currentOrder, outOfHours, orderid };
    // this.CarService.updateOutOfHours(outOfHours);
    this.setToStorage(currentOrder);
    // this.order.next(currentOrder);
    // console.log(this.order.value);
  }
  getOutOfHours(): Number | undefined {
    return this.order.value?.outOfHours;
  }

  tempReservationToOrderHelper(reservation: any, selectedCar: any) {
    const currentOrder = this.order.value;
    const searchFilters = JSON.parse(
      sessionStorage.getItem('searchFilters') || ''
    );
    // console.log(searchFilters);
    if (!currentOrder || !searchFilters) return currentOrder;
    const order: Order = {
      ...currentOrder,
      car: {
        id: selectedCar._id,
        price: reservation.carData.carPrice,
        fdPrice: selectedCar.fdPrice,
        pickupDate: new Date(searchFilters.pickupDate),
        returnDate: new Date(searchFilters.returnDate),
        activeUpgrades: reservation.carData.equipment,
      },
      station: reservation.station,
    };

    return order;
  }
  resolveCoupon(coupon: any | undefined) {
    // console.log(coupon);
    const today = new Date();
    let currentOrder = this.getFromStorage();
    if (!currentOrder) return;
    const { car, station } = currentOrder;
    // let currentOrder = this.getFromStorage()
    // console.log('$$$', currentOrder);
    if (!coupon) return { message: 'Coupon doesnt exist', status: false };
    coupon.validFrom = new Date(coupon.validFrom);
    coupon.validTo = new Date(coupon.validTo);
    coupon.validPickupFrom = new Date(coupon.validPickupFrom);
    coupon.validPickupTo = new Date(coupon.validPickupTo);

    coupon.validTo = new Date(coupon.validTo);
    car!.pickupDate = new Date(car!.pickupDate);

    if (!coupon.enabled) return { message: 'Coupon disabled', status: false };
    if (!coupon.categories.includes(car!.id))
      return { message: 'Wrong category', status: false };
    if (!coupon.station.includes(station!.id))
      return { message: 'Wrong pickup place', status: false };

    if (coupon.validFrom > today || coupon.validTo < today)
      return { message: 'Coupon expired', status: false };
    if (
      coupon.validPickupFrom > car!.pickupDate ||
      coupon.validPickupTo < car!.pickupDate
    )
      return {
        message: 'Coupon doesnt cover these pickup dates ',
        status: false,
      };
    if (coupon.discountPrice && currentOrder.car!.price < coupon.discountPrice)
      return {
        message: 'Coupon discount surpasses rent cost ',
        status: false,
      };
    if (coupon.timesLimit === 0) {
      return {
        message: "Reached coupon's use limit",
        status: false,
      };
    }
    let returnObject = {
      value: coupon.discountPercent
        ? coupon.discountPercent
        : coupon.discountPrice,
      type: coupon.discountPercent ? 'percentage' : 'flat',
      message: coupon.couponType.messageToShow,
      hideDiscountedSum: coupon.couponType.hideDiscountedSum,
      status: true,
    };

    currentOrder = {
      ...currentOrder,
      coupon: returnObject,
    };
    this.setToStorage(currentOrder);
    // this.order.next(currentOrder);
    this.CouponService.setCoupon(coupon);
    this.updateOnlineSum();
    return returnObject;
  }
  sendPrepaymentEmail(body: any) {
    return this.PagesService.post('email/prepayment', body);
  }

  generateOrderId() {
    return Math.floor(Math.random() * 99999999999999).toString();
  }
  getFromStorage(): Order | null {
    // return JSON.parse(
    //   sessionStorage.getItem('order')
    //     ? sessionStorage.getItem('order') || '{}'
    //     : '{}'
    // );
    return this.order.value;
  }
  setToStorage(data: Order | null) {
    this.order.next(data);
    // sessionStorage.setItem('order', JSON.stringify(data));
    return;
  }
  emptyOrder() {
    this.order.next(null);
  }
}
