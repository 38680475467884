import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-front-reusable-offer',
  templateUrl: './front-reusable-offer.component.html',
  styleUrls: ['./front-reusable-offer.component.css'],
})
export class FrontReusableOfferComponent implements OnInit {
  @Input() imageUrl: string = '';
  @Input() title: any = { en: '', el: '' };
  @Input() subtitle: any = { en: '', el: '' };
  @Input() description: any = { en: '', el: '' };
  @Input() rotation: string = '';
  @Input() textOrientation: string = '';
  @Input() url!: string;
  lang = 'el';
  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((data) => {
      this.lang = data.lang;
    });
  }

  ngOnInit(): void {}
}
