import { TranslateService } from '@ngx-translate/core';
import { FaqService } from './../faq.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  lang = this.TranslateService.currentLang;
  constructor(
    private FaqService: FaqService,
    private TranslateService: TranslateService
  ) {
    this.TranslateService.onLangChange.subscribe((change: any) => {
      this.lang = change.lang;
    });
  }
  privacy$: Observable<any> = this.FaqService.getPolicy();
  ngOnInit(): void {}
}
