import { ModalService } from './../services/modal.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; description: string; route: string },
    private ModalService: ModalService
  ) {}

  ngOnInit(): void {}
  closeModal() {
    this.ModalService.closeDialog();
  }
}
