import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-insurance-coverage',
  templateUrl: './insurance-coverage.component.html',
  styleUrls: ['./insurance-coverage.component.css']
})
export class InsuranceCoverageComponent implements OnInit {
  data:any
  lang = 'el'
  constructor(private fService:FaqService,private translate:TranslateService) {
    this.translate.onLangChange.subscribe(x=>this.lang=x.lang)
  } 

  ngOnInit(): void {
    this.lang = this.translate.currentLang
    this.getData()
  }

  async getData(){
    this.data = await this.fService.getInsurance().toPromise()
  }
}
