import { DomSanitizer } from '@angular/platform-browser';
import { SessionLimiterService } from './../utils/session-limiter.service';
import { CarService } from './../../pages/car-selection/car.service';
import { OrderService } from './../../pages/car-selection/order.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FrontpageService } from '../../pages/frontPage/frontpage.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { formFilterData } from '../utils/formFilteringData';
import { Observable, merge, of, timer } from 'rxjs';
import { LANG } from '../interface/lang';

@Component({
  selector: 'app-filtering',
  templateUrl: './filtering.component.html',
  styleUrls: ['./filtering.component.css'],
})
export class FilteringComponent implements OnInit {
  @Input() stepper: boolean = false;
  @Input() customH: any;
  @Input() customW: any;
  @Input() additional: any;
  @Input() searchForm!: UntypedFormGroup;
  @Input() ageSelected = false;
  @Input() locationSelected = false;
  @Output() changeTab = new EventEmitter();
  lang: LANG = 'el';
  windowWidth: keyof (typeof this.backgroundImageDictionary)[LANG] = '1715';
  //removes the floating text "Κανε κρατηση τωρα" και "Ταξιδεψε σε ολο τον κοσμο"
  structureMode: 'normal' | 'blackFriday' = 'normal';
  showTravelAndBookReservationTitle =
    this.structureMode === 'normal' ? true : false;
  backgroundImageDictionary: {
    en: { [key: string]: string };
    el: { [key: string]: string };
  } = {
    en: {
      '2400': 'assets/images/black-friday/1715x800-en.png',
      '1715': 'assets/images/black-friday/1715x800-en.png',
      '1366': 'assets/images/black-friday/1366x600-en.png',
      '1024': 'assets/images/black-friday/1024x500-en.png',
      '768': 'assets/images/black-friday/389x900-en.png',
    },
    el: {
      '2400': 'assets/images/black-friday/1715x800-el.png',
      '1715': 'assets/images/black-friday/1715x800-el.png',
      '1366': 'assets/images/black-friday/1366x600-el.png',
      '1024': 'assets/images/black-friday/1024x500-el.png',
      '768': 'assets/images/black-friday/389x900-el.png',
    },
  };

  searchKey = new FormControl('');
  formattedLocations$ = this.frontService.getLocation('locations').pipe(
    map((locations) => {
      locations.sort((a: any, b: any) => {
        return a.name.el.charCodeAt() - b.name.el.charCodeAt();
      });
      return locations.filter((x: any) => x.code);
    })
  );
  timer$ = this.SessionLimiterService.countDown();
  locations$ = merge(
    this.formattedLocations$,
    this.searchKey.valueChanges.pipe(
      mergeMap((key: any) =>
        this.formattedLocations$.pipe(
          map((locations) =>
            locations.filter((location: any) =>
              this.formatSearchStrings(location.name[this.lang]).includes(
                this.formatSearchStrings(key)
              )
            )
          )
        )
      )
    )
  );

  selectedCars: any;

  enablePromt = false;
  timetable: string[] = [];
  pickUpDateFormat: any;
  returnDateFormat: any;
  class: string = '';

  greekTab = 'active show';
  iframeUrl: any = {
    en: this.DomSanitizer.bypassSecurityTrustResourceUrl(
      'https://static.drive-hellas.com/abroad-en.html'
    ),
    el: this.DomSanitizer.bypassSecurityTrustResourceUrl(
      'https://static.drive-hellas.com/abroad.html'
    ),
  };
  // pickupLocationSelect = { name: { en: '', el: '' }, code: '' };
  foreignTab = '';

  constructor(
    private frontService: FrontpageService,
    private OrderService: OrderService,
    private router: Router,
    private translate: TranslateService,
    private CarService: CarService,
    private SessionLimiterService: SessionLimiterService,
    private DomSanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.translate.onLangChange.subscribe((data) => {
      this.lang = data.lang as LANG;
    });
    this.initForm();
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang as LANG;
    this.windowWidth = this.closestWindowWidth();

    this.stopWatch();
    if (!this.searchForm) return;
    this.searchForm.valueChanges.subscribe((res) => {
      // console.log(res);
      if (res.pickupDate > res.returnDate) {
        const pickupDate = new Date(res.pickupDate);
        pickupDate.setDate(pickupDate.getDate() + 7);
        this.searchForm.patchValue({ returnDate: pickupDate });
      }

      if (
        res.pickupDate.getTime() === res.returnDate.getTime() &&
        parseInt(res.pickupTime, 10) > parseInt(res.returnTime, 10)
      ) {
        this.searchForm.patchValue({
          returnTime: res.pickupTime,
          pickupTime: '09:00',
        });
      }
    });
  }

  private initForm(): void {
    // Only initialize if searchForm hasn't been provided as input
    if (!this.searchForm) {
      this.searchForm = this.fb.group({
        pickupLocation: [null],
        pickupDate: [null],
        pickupTime: [null],
        returnDate: [null],
        returnTime: [null],
      });
    }
  }

  // defaultOptionMatSelect(x: any, y: any) {
  //   if (typeof x === 'object') {
  //     return x.code === 'SKGT01' ? x : '';
  //   }
  // }
  search(key: any) {
    this.searchKey.patchValue(key.value);
  }
  stopWatch() {
    for (let i: any = 0; i < 24; i++) {
      if (i < 10) {
        i = `0${i}`;
      }
      this.timetable.push(i + ':00');
      this.timetable.push(i + ':30');
    }
  }
  datePickerFilterPickUp = (date: Date | null): boolean => {
    const myDate = date ? date : new Date();
    if (myDate < new Date()) {
      return false;
    } else {
      return true;
    }
  };
  datePickerFilterDropOff = (date: Date | null): boolean => {
    const myDate = date ? date : new Date();
    const pickDate = new Date(this.searchForm.controls['pickupDate'].value);
    if (myDate < (pickDate ? pickDate : new Date())) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit(value: any) {
    // console.log(this.searchForm.value);
    if (this.searchForm.controls['pickupLocation'].value.code) {
      this.locationSelected = true;
    }
    // console.log(this.searchForm.value, this.ageSelected);
    if (!this.ageSelected || !this.locationSelected) {
      this.enablePromt = true;
      return;
    }

    this.OrderService.updateStation(
      this.searchForm.controls['pickupLocation'].value
    );
    sessionStorage.setItem(
      'searchFilters',
      JSON.stringify(this.searchForm.value)
    );
    this.pickUpDateFormat = value.pickupDate;
    this.returnDateFormat = value.returnDate;

    let data = formFilterData(value);
    // console.log(data);
    this.CarService.initReservationObject(
      this.searchForm.controls['pickupLocation'].value,
      data
    );
    this.calculateOutOfHours();
    this.SessionLimiterService.startCounter();
    this.router.navigate(['/rent/choose-car'], {
      queryParams: {
        pickupTime: value.pickupTime,
        returnTime: value.returnTime,
        returnDate: value.returnDate,
        pickupDate: value.pickupDate,
        pickupLocation: value.pickupLocation.code,
        returnLocation: value.pickupLocation.code,
      },
    });
    this.goToFirstTab();
  }

  goToFirstTab() {
    this.changeTab.emit(0);
  }
  compare(o1: any, o2: any) {
    return o1.name.en == o2.name.en && o1.code == o2.code;
  }
  compare1(o1: any, o2: any) {
    return o1 === o2;
  }
  tabChangeToForeign() {
    this.greekTab = '';
    this.foreignTab = 'active show';
  }
  tabChangeToGreece() {
    this.greekTab = 'active show';
    this.foreignTab = '';
  }
  calculateOutOfHours() {
    const pickupTimeForCalculations = this.searchForm.controls[
      'pickupTime'
    ].value.replace(':', '.');
    const returnTimeForCalculations = this.searchForm.controls[
      'returnTime'
    ].value.replace(':', '.');
    const pick = parseFloat(pickupTimeForCalculations);
    const returnT = parseFloat(returnTimeForCalculations);
    if ((pick > 21 || pick < 8) && (returnT > 21 || returnT < 8)) {
      this.OrderService.updateOutOfHours(80);
      this.CarService.updateOutOfHours(80);
    } else if (returnT > 21 || returnT < 8) {
      this.OrderService.updateOutOfHours(40);
      this.CarService.updateOutOfHours(40);
    } else if (pick > 21 || pick < 8) {
      this.OrderService.updateOutOfHours(40);
      this.CarService.updateOutOfHours(40);
    } else {
      this.OrderService.updateOutOfHours(0);
      this.CarService.updateOutOfHours(0);
    }
  }
  formatSearchStrings(key: string) {
    const returnString = key
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return returnString.replace('ς', 'σ');
  }
  closestWindowWidth(): keyof (typeof this.backgroundImageDictionary)[LANG] {
    const width = window.innerWidth;
    return (
      Object.keys(this.backgroundImageDictionary[this.lang])
        .map(Number)
        .filter((w) => w >= width)
        .sort((a, b) => a - b)[0] || '1715'
    );
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const { innerWidth, innerHeight } = event.target as Window;

    switch (true) {
      case innerWidth >= 2400:
        this.windowWidth = '2400';
        break;
      case innerWidth >= 1715:
        this.windowWidth = '1715';
        break;
      case innerWidth >= 1366:
        this.windowWidth = '1366';
        break;
      case innerWidth >= 1024:
        this.windowWidth = '1024';
        break;
      case innerWidth >= 768:
        this.windowWidth = '768';
        break;
    }
    // console.log(
    //   innerWidth,
    //   this.backgroundImageDictionary[this.lang][this.windowWidth]
    // );
  }
}
