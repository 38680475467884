import { PreloadAllModules, RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core/core.module';

import { FrontpageComponent } from './pages/frontPage/frontpage/frontpage.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { translateBrowserLoaderFactory } from './core/core/services/translate-browser.loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { ComingSoonModule } from './shared/coming-soon/coming-soon.module';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NotFoundComponent } from './core/core/not-found/not-found.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { RecaptchaModule } from 'ng-recaptcha';
import { ContactComponent } from './pages/faq/contact/contact.component';
import { DeductibleAmountsComponent } from './pages/faq/deductible-amounts/deductible-amounts.component';
import { DrivingTipsDetailsComponent } from './pages/faq/driving-tips-details/driving-tips-details.component';
import { DrivingTipsComponent } from './pages/faq/driving-tips/driving-tips.component';
import { FaqComponent } from './pages/faq/faq/faq.component';
import { InsuranceCoverageComponent } from './pages/faq/insurance-coverage/insurance-coverage.component';
import { PrivacyPolicyComponent } from './pages/faq/privacy-policy/privacy-policy.component';
import { SiteMapComponent } from './pages/faq/site-map/site-map.component';
import { TermsComponent } from './pages/faq/terms/terms.component';
import { BeAPartnerComponent } from './pages/about-us/be-a-partner/be-a-partner.component';

@NgModule({
  declarations: [
    AppComponent,
    FrontpageComponent,
    NotFoundComponent,
    TermsComponent,
    FaqComponent,
    DrivingTipsComponent,
    SiteMapComponent,
    DeductibleAmountsComponent,
    InsuranceCoverageComponent,
    DrivingTipsDetailsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    BeAPartnerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    SharedModule,
    CdkAccordionModule,
    RecaptchaModule,
    AppRoutingModule,
    CoreModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
    TransferHttpCacheModule,
    ComingSoonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [TranslateModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      swUpdate.unrecoverable.subscribe((event: any) => {
        console.warn('SW Error:', event.reason);
      });
    }
  }
}
