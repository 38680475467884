<app-header></app-header>
<div class="spacer"></div>
<div class="mb-3">
  <ng-container *ngIf="url !== '/'">
    <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
      <div
        class="breadcrumbContainer d-flex mb-3"
        style="margin-left: 5%; margin-right: 5%"
      >
        <a
          [ngClass]="breadcrumb.active ? 'active' : 'inactive'"
          class="text-lg text-bold"
          *ngFor="let breadcrumb of breadcrumbs; index as i"
          [routerLink]="!breadcrumb.active ? breadcrumb.url : null"
        >
          <span *ngIf="breadcrumb.label.length" style="padding-right: 1rem">
            {{ breadcrumb.label | translate }}</span
          >
          <span *ngIf="!breadcrumb.label.length" style="padding-right: 1rem">
            {{ breadcrumb.label[lang] }}</span
          >

          <span style="padding-right: 1rem" *ngIf="i !== breadcrumbs.length - 1"
            >/</span
          >
        </a>
      </div>
    </ng-container>
  </ng-container>

  <script
    id="CookieDeclaration"
    src="https://consent.cookiebot.com/021dd579-cce7-4c22-8de7-de2e5ea6ce13/cd.js"
    type="text/javascript"
    async
  ></script>
  <router-outlet class="sw-main" (activate)="scrollTop($event)"></router-outlet>
  <div
    class="d-flex flex-column flex-md-row justify-content-between align-items-center"
  >
    <div class="d-flex flex-column espa-position">
      <ng-container *ngFor="let item of espa">
        <a target="_blank" style="margin-bottom: 10px" [href]="item.link[lang]">
          <img class="espa-icons" [src]="item.img[lang]" alt="espa" />
        </a>
      </ng-container>
    </div>
  </div>
  <app-footer></app-footer>
</div>
