<div class="myContainer">
  <form action="" [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm)">
    <div class="d-flex justify-content-between flex-wrap">
      <div class=" {{ item.class }}" *ngFor="let item of contactElements">
        <mat-form-field appearance="outline" class="mat-form w-100">
          <mat-label>{{ item.title[lang] }}</mat-label>
          <input
            matInput
            formControlName="{{ item.control }}"
            placeholder=""
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="">
      <re-captcha
        class=""
        (resolved)="resolved($event)"
        siteKey="{{ captchaKey }}"
      ></re-captcha>
      <div class="spacer mb-4"></div>
      <button mat-flat-button class="bg-red text-white price-check">
        {{ "driver.button" | translate }}
      </button>
    </div>
  </form>
</div>
