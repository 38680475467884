import { env } from './env';

export const environment = {
  ...env,
  production: false,
  PORT: 4049,
  Url: 'https://api.drive-hellas.com/api',
  Version: 'v2',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBRHIwruX7jREbcXvcXzEx5XqhwAcij8zo',
  RECAPTCHA_SITE_KEY: '6LdIc18UAAAAANcWUNBUYbSiOk4nUz7xmrd_1MQy',
  defaultLanguage: 'el',
  PAYPAL_CLIENT_ID:
    'AaY-xnBmLTKVtNIQKrcpd6ywHWAM6K31B-RLgNbkzW3EJnXogbHB1R80AAWRYAUAIQn3TbdmJY1JTv98',
  SANDBOX_CLIENT_ID:
    'Ab6zmcH6eEk0ZY4CCvFM2oDdgxBqL0olAceGdsqA837JpfS0oFKR-2FMwCp1sQp8x6BfWM79Ng5XL3zW',
  ALPHA_MID: '0020753745',
  ALPHA_POST_TEST: 'https://alpha.test.modirum.com/vpos/shophandlermpi',
  ALPHA_POST_LIVE: 'https://www.alphaecommerce.gr/vpos/shophandlermpi',
};
