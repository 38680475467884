import { ModalService } from 'src/app/core/core/services/modal.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, interval, timer } from 'rxjs';
import { filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BrowserService } from 'src/app/core/core/services/browser.service';

@Injectable({
  providedIn: 'root',
})
export class SessionLimiterService {
  #title = {
    en: 'The available time has expired.',
    el: 'Ο διαθέσιμος χρόνος έχει λήξει.',
  };
  #description = {
    en: 'Please search for vehicles again.',
    el: 'Παρακαλούμε πραγματοποιήστε εκ νέου αναζήτηση οχημάτων.',
  };
  #duration = 30 * 60 * 1000;
  #timerInitDate: BehaviorSubject<Date | null> =
    new BehaviorSubject<Date | null>(null);
  timerInitDateObservable = this.#timerInitDate.asObservable();

  constructor(
    private ModalService: ModalService,
    private TranslateService: TranslateService,
    private router: Router,
    private BrowserService: BrowserService
  ) {}

  startCounter() {
    const currentDate = new Date();
    this.#timerInitDate.next(currentDate);
    this.saveTimerInitDate(currentDate);
  }
  stopCounter() {
    this.#timerInitDate.next(null);
    this.deleteTimerInitDate();
  }

  countDown(): Observable<number> {
    return interval(1000).pipe(
      // tap(() => console.log('tick')),
      switchMap((time) =>
        this.timerInitDateObservable.pipe(
          filter((date) => !!date),
          map((n) => this.timeRemaining()),
          tap((time) => {
            // console.log(time);
            if (time <= 0) this.showSessionEndModal();
          })
        )
      )
      // takeWhile((time) => time >= 0)
    );
  }
  private timeRemaining() {
    if (!this.#timerInitDate.value) return -1;
    const timeEnd =
      new Date(this.#timerInitDate.value).getTime() + this.#duration;
    // console.log({ timeEnd });
    // console.log(new Date(this.#timerInitDate.value).getTime());
    // console.log(timeEnd - new Date().getTime());
    return timeEnd - new Date().getTime();
  }
  private showSessionEndModal() {
    this.stopCounter();
    this.BrowserService.clearLocalStorage();

    const lang: keyof { el: string; en: string } = this.TranslateService
      .currentLang as 'en' | 'el';
    // window.location.href = '';

    this.ModalService.openDialog({
      title: this.#title[lang],
      description: this.#description[lang],
      route: '',
    });
    this.router.navigate([]);
  }
  private saveTimerInitDate(initDate: Date) {
    this.BrowserService.setLocalStorage('timerInit', JSON.stringify(initDate));
  }
  getAndSetTimerInitDate() {
    const initDate = this.BrowserService.getLocalStorage('timerInit');
    // console.log({ initDate });
    if (initDate && !this.#timerInitDate.value) {
      const savedDate = new Date(JSON.parse(initDate));

      // console.log('subject', this.#timerInitDate.value);
      // console.log('parsed date', JSON.parse(initDate));
      // console.log({ savedDate });
      this.#timerInitDate.next(savedDate);
    }
  }
  private deleteTimerInitDate() {
    this.BrowserService.removeLocalStorage('timerInit');
  }
}
