<div class="myContainer1">
  <div class="footer-image-container">
    <img
      loading="lazy"
      class="footer-image"
      src="/assets/images/newsletter-min.jpg"
      alt=""
    />

    <p class="news-cover">NEWSLETTER</p>
    <div class="news-text text-center">
      <h3 class="mb-0 fw-bold">
        {{ "frontPage.footer.subscribe" | translate }}
      </h3>
      <p class="">{{ "frontPage.footer.updates" | translate }}</p>
    </div>
    <div class="email-form">
      <div
        class="d-flex flex-column flex-md-row form-row align-items-center submit-cont"
      >
        <div class="col-auto input-cont">
          <input
            type="text"
            aria-labelledby="newsletterInput"
            class="input-field form-control mb-2"
            id="newsletterInput"
            [formControl]="emailControl"
            placeholder="{{ 'frontPage.footer.emailAdd' | translate }}"
          />
        </div>
        <div class="col-auto ms-3">
          <button
            (click)="submitEmail(emailControl)"
            class="submit-button bg-red btn btn-primary mb-2 text-black"
          >
            {{ "frontPage.footer.sub" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center icon-strip my-5">
    <div class="text-cont me-4 text-nowrap">
      <p class="mb-0 small-text">{{ "frontPage.footer.follow" | translate }}</p>
      <p class="small-bold mb-0">Social Media</p>
    </div>
    <div>
      <a href="https://www.facebook.com/drivehellaspage/" target="_blank">
        <img
          class="icon"
          src="/assets/images/Group 204.svg"
          alt="facebook image"
      /></a>
    </div>
    <div>
      <a href="https://twitter.com/drivehellas" target="_blank"
        ><img
          class="icon"
          src="/assets/images/Group 203.svg"
          alt="twitter image"
      /></a>
    </div>
    <div>
      <a href="https://www.instagram.com/drivehellas" target="_blank"
        ><img
          class="icon"
          src="/assets/images/Group 202.svg"
          alt="instagram image"
      /></a>
    </div>
  </div>
</div>

<div class="bg-red softweb d-flex justify-content-between align-items-center">
  <div class="copyright text-black">
    © Copyright {{ thisYear }} Drive Hellas - Car Rental Company
  </div>
  <div class="">
    <img class="drive-image" src="/assets/images/drive-white.svg" alt="" />
  </div>
  <div class="powered d-flex align-items-center">
    <span class="text-black pt-2 me-2 powered-text">Powered by</span>
    <img src="/assets/images/softweb.svg" alt="" />
  </div>
</div>
<div
  class="footer bg-black row d-flex align-items-center text-white flex-column flex-md-row"
>
  <!-- first column -->
  <div class="col-12 col-md-5">
    <ul class="list">
      <li>
        <a routerLink="about-us/branches">{{
          "frontPage.footer.column1.stores" | translate
        }}</a>
      </li>
      <li>
        <a routerLink="about-us/discover-greece">
          {{ "frontPage.footer.column1.discover" | translate }}
        </a>
      </li>
      <li>
        <a routerLink="about-us/stay-and-drive"> Stay & Drive</a>
      </li>
      <li>
        <a routerLink="about-us/news">
          {{ "frontPage.footer.column1.news" | translate }}</a
        >
      </li>
      <li>
        <a routerLink="blog">
          {{ "frontPage.footer.column1.notes" | translate }}</a
        >
      </li>
    </ul>
  </div>
  <!-- second column -->

  <div class="col-12 col-md-4">
    <ul class="list">
      <li>
        <a routerLink="contact">
          {{ "frontPage.footer.column1.contact" | translate }}</a
        >
      </li>
      <li>
        <a
          style="text-decoration: none; color: white"
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeooem8oW3NxJE9gQLSm0Wmf2pF5IpfgCeZDdw9NeFqCQYzEQ/viewform"
          >{{ "frontPage.footer.column2.opinion" | translate }}</a
        >
      </li>

      <li>
        <a routerLink="faq">{{ "frontPage.footer.column2.faq" | translate }}</a>
      </li>
      <li>
        <a routerLink="terms">{{
          "frontPage.footer.column2.terms" | translate
        }}</a>
      </li>
      <!-- <li>{{ "frontPage.footer.column2.terms1" | translate }}</li> -->
      <li>
        <a routerLink="privacy-policy">{{
          "frontPage.footer.column2.policy" | translate
        }}</a>
      </li>
      <li>
        <a routerLink="about-us/sitemap">Sitemap</a>
      </li>
    </ul>
  </div>
  <!-- third column -->

  <div class="col-12 col-md-3">
    <ul class="list no-hand mt-3">
      <li>{{ "frontPage.footer.column3.location" | translate }}</li>
      <li>{{ "frontPage.footer.column3.register" | translate }}</li>
      <li>{{ "frontPage.footer.column3.zipCode" | translate }}</li>
      <li><a href="tel:+30 2310 472600">+30 2310 472600</a></li>
      <li>rentacar&#64;drive-hellas.gr</li>
      <li>
        {{ "frontPage.footer.column3.schedule" | translate }} <br />
        {{ "frontPage.footer.column3.schedule1" | translate }}
      </li>
    </ul>
  </div>
  <!-- <div>
    <img
      loading="lazy"
      class="footer-last-image"
      src="/assets/images/footer-image.jpeg"
      alt=""
    />
  </div> -->
</div>
