import { DomSanitizer } from '@angular/platform-browser';
import { PagesService } from 'src/app/pages/pages.service';
import { Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeasingService {
  constructor(private pService: PagesService) {}

  getLeasing() {
    return this.pService
      .get(
        '/posts?field=order&order=ASC&filters={"category":{"$in":["5b20fb3da64da2141479314e"]}}'
      )
      .pipe(map((items: any) => items.filter((item: any) => item.enabled)));
  }
  getOpLeasing() {
    return this.pService.get('/pages/name/operating-leasing-autokinitou');
  }

  leasingForm(body: any) {
    return this.pService.post('email/form', body);
  }
  beApartnerForm(body: any) {
    return this.pService.post('email/form', body);
  }
  getLeasingFilters() {
    return this.pService.get('/filters').pipe(
      map((items: any) => {
        items.sort((a: any, b: any) => {
          return a.filterPosition - b.filterPosition;
        });
        return items.filter((item: any) =>
          item.displayLocations.includes('leasing')
        );
      })
    );
  }
  getOffers() {
    return this.pService.get('offers').pipe(
      map((offers: any) => {
        // console.log(offers);
        offers.sort((a: any, b: any) => {
          return b.position - a.position;
        });

        return offers.filter((item: any) =>
          item.displayLocations.includes('leasing')
        );
      })
    );
  }
  getSynergeia() {
    return this.pService.get('synergeia');
  }
}
