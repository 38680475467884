<div class="myContainer">
  <h1
    style="font-size: 2rem; font-weight: 600; margin-bottom: 2rem"
    [innerHtml]="title?.name[lang]"
  ></h1>

  <div class="cont d-flex flex-column flex-md-row row">
    <a
      routerLink="{{ item.slug }}"
      class="iteration d-flex flex-column mb-5"
      *ngFor="let item of data"
    >
      <img
        style="width: 100%; height: auto"
        [src]="getImageUrl(item.images[0].filename)"
        [alt]="item.alt || 'driving tips image'"
      />
      <div class="title bg-red text-white d-flex">
        <span class="px-2">
          {{ item.title[lang] }}
        </span>
      </div>
    </a>
  </div>
</div>
