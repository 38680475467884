import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BrowserService } from 'src/app/core/core/services/browser.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedCarService {
  selectedCarSource = new BehaviorSubject<any>(null);

  constructor(private BrowserService: BrowserService) {}

  getFromStorage() {
    return JSON.parse(
      this.BrowserService.getLocalStorage('selectedCar')
        ? this.BrowserService.getLocalStorage('selectedCar') || '{}'
        : '{}'
    );
  }
  setToStorage(data: object) {
    this.BrowserService.setLocalStorage('selectedCar', JSON.stringify(data));
    return;
  }
}
