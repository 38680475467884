import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  readonly url = `${environment.Url}/v2`;
  myHeaders = {
    Origin: 'http://localhost:4200',
    'Content-Type': 'application/json',
  };
  requestOptions = {
    headers: new HttpHeaders(this.myHeaders),
  };

  constructor(private http: HttpClient) {}

  get<T>(url: string) {
    return this.http.get(`${this.url}/${url}`) as Observable<T>;
  }
  getWithAuth<T>(url: string) {
    return this.http.get(
      `${this.url}/${url}`,
      this.requestOptions
    ) as Observable<T>;
  }

  post<T>(url: string, body: any, headers?: HttpHeaders) {
    return this.http.post(`${this.url}/${url}`, body, {
      headers,
    }) as Observable<T>;
  }
  put<T>(url: string, id: string) {
    return this.http.put(`${this.url}/${url}/${id}`, {});
  }
  postNoBaseUrl<T>(url: string, body: any, headers?: HttpHeaders) {
    return this.http.post(url, body, {
      headers,
    }) as Observable<T>;
  }
}
