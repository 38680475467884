import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  private titleBase: string = ` | Drive Hellas`;

  public setTitle(titlePart: string): void {
    this.title.setTitle(titlePart + this.titleBase);
    this.setMetaTitle(titlePart + this.titleBase);
  }

  public setDescription(description: string): void {
    this.meta.updateTag({
      name: 'description',
      content: description + this.titleBase,
    });
  }
  setMetaTitle(title: string) {
    this.meta.updateTag({ name: 'title', content: title });
  }

  public setKeywords(keywords: string[]): void {
    this.meta.updateTag(
      {
        name: 'keywords',
        content: keywords.reduce((prev, curr) => (prev += `, ${curr}`)),
      },
      'name=keywords'
    );
  }
}
