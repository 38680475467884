import { TranslateService } from '@ngx-translate/core';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  router = inject(Router);
  platformId = inject(PLATFORM_ID);
  private breadcrumbs = new BehaviorSubject<
    {
      url: string;
      label: any;
      active: boolean;
    }[]
  >([]);
  breadcrumbs$: Observable<any> = this.breadcrumbs.asObservable();

  urlToTranslationDictionary: Record<string, string> = {
    '': 'frontPage.navbar.home',
    oximata: 'frontPage.navbar.rent.dropDown.vehicles',
    'enoikiasi-oximatos': 'frontPage.navbar.rent.title',
    'extra-services': 'frontPage.navbar.rent.dropDown.extraServices',
    'drive-monthly': 'frontPage.navbar.rent.dropDown.midTermRent',
    offers: 'frontPage.navbar.rent.dropDown.offers',
    leasing: 'frontPage.navbar.leasing.title',
    'leasing-offers': 'frontPage.navbar.leasing.dropDown.offers',
    form: 'frontPage.navbar.leasing.dropDown.leasingRequest',
    van: 'frontPage.navbar.rentVan.title',
    'leitourgiki-misthosi-van': 'frontPage.navbar.rentVan.dropDown.opLeasing',
    'van-safety': 'frontPage.navbar.rentVan.dropDown.vSafety',
    'about-us': 'frontPage.navbar.whoWeAre.title',
    'be-a-partner': 'Be a partner',
    blog: 'Blog',
    branches: 'frontPage.navbar.whoWeAre.dropDown.stores',
    news: 'frontPage.navbar.whoWeAre.dropDown.news',
    'discover-greece': 'frontPage.footer.column1.discover',
    'stay-and-drive': 'Stay & Drive',
    contact: 'frontPage.footer.column1.contact',
    faq: 'frontPage.footer.column2.faq',
    terms: 'frontPage.footer.column2.terms',
    'privacy-policy': 'frontPage.footer.column2.policy',
    sitemap: 'Sitemap',
  };

  setIdPageBreadcrumb({ label, slug }: { label: any; slug: string }) {
    const currentValue = this.breadcrumbs.value;
    const index = currentValue.findIndex((x) => x.label === slug);
    if (index === -1) return;
    currentValue[index].label = label;
    this.breadcrumbs.next(currentValue);
  }

  configureBreadcrumbs(event: NavigationEnd) {
    let url = event.url;
    //remove params
    if (event.url.includes('?')) url = url.split('?')[0];

    const separatedUrl = url.split('/');
    const formattedBreadcrumbs = separatedUrl.map(
      (url: string, index: number, array: string[]) => {
        //current breadcrumb
        const fullUrl = array.slice(0, index + 1).join('/');
        return {
          label: this.urlToTranslationDictionary[url]
            ? this.urlToTranslationDictionary[url]
            : url,
          url: url === '' ? '/' : fullUrl,
          active: array.length - 1 === index,
        };
      }
    );
    this.breadcrumbs.next(formattedBreadcrumbs);
  }
}
