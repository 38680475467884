import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PagesService } from '../../pages/pages.service';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { BrowserService } from 'src/app/core/core/services/browser.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  private _lang: any;
  @Input()
  set lang(value: any) {
    this._lang = value;
  }
  get lang() {
    return this._lang;
  }
  @ViewChild('mapContainer', { static: false }) gmap!: ElementRef;
  map!: google.maps.Map;

  loader = new Loader({
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });
  options: google.maps.MapOptions = {
    // mapId: 'DEMO_MAP_ID',
    center: { lat: 37.98381, lng: 23.727539 },
    zoom: 6,
    draggable: false,
    disableDefaultUI: true,
    styles: [{ stylers: [{ saturation: -100 }] }],
  };

  infoOpen: boolean = false;

  aboutUsContext: any;
  aboutUsTitle: any;
  storesArray: any = [];

  icon = 'assets/images/red-marker.svg';

  constructor(
    private service: PagesService,
    private BrowserService: BrowserService
  ) {}

  ngOnInit(): void {
    this.service.get('pages/name/about').subscribe((res: any) => {
      this.aboutUsContext = { el: res[0].bodyEl, en: res[0].bodyEn };
      this.aboutUsTitle = { el: res[0].titleEl, en: res[0].titleEn };
      // console.log(this.aboutUsContext);
    });
    this.service.get('stores').subscribe(
      (res: any) => {
        // console.log(res);
        let i, item;
        for ([i, item] of res.entries()) {
          this.storesArray[i] = {
            position: {
              lat: item.location.coordinates[0],
              lng: item.location.coordinates[1],
            },
            lat: item.location.coordinates[0],
            lng: item.location.coordinates[1],
            descr: item.description,
            name: item.name,
            display: false,
          };
        }
        // console.log(this.storesArray);
      },
      (error) => {}
    );
  }
  ngAfterViewInit(): void {
    this.initializeMap();
  }
  async initializeMap() {
    const { Map } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;

    const mapProperties = {
      mapId: 'DriveStoresMap',
      center: new google.maps.LatLng(37.98381, 23.727539),
      zoom: 6,
      draggable: true,
      disableDefaultUI: true,
      styles: [{ stylers: [{ saturation: -100 }] }],
    };
    this.map = new Map(this.gmap.nativeElement, mapProperties);

    await this.populateMapWithMarkers();
  }
  returnTop() {
    this.BrowserService.scrollTo(0, 0);
  }
  toggleMarkerInfo(index: number, open: boolean) {
    this.storesArray[index].display = open;
  }
  async populateMapWithMarkers() {
    const { InfoWindow } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } =
      (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;

    for (const marker of this.storesArray) {
      const markerElement = new AdvancedMarkerElement({
        title: 'test',
        map: this.map,
        position: marker.position,
        gmpClickable: true,
      });
      const infoWindow = new InfoWindow({
        content: this.buildContent(marker),
        ariaLabel: 'Uluru',
      });
      //@ts-ignore
      markerElement.content.addEventListener('click', () => {
        infoWindow.open({
          anchor: markerElement,
          map: this.map,
        });
      });
    }
  }

  buildContent(data: { name: string; descr: string }): any {
    const content = document.createElement('div');
    content.classList.add('property');
    content.innerHTML = `<h4>${data.name}</h4><p>${data.descr}</p>`;
    return content;
  }
}
