import { env } from 'src/environments/env';
import { PagesService } from './../../pages/pages.service';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

interface tikTokBody {
  event_source: string;
  event_source_id: string;
  data: {
    event: string;
    event_time: number;
    properties: { value: number; currency: string };
    contents: {
      content_id: string;
      content_type: string;
      content_name: string;
    }[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class TiktokService {
  baseUrl = env.tikTokUrl;
  constructor(private PagesService: PagesService) {}

  viewContent(content_type: string, content_name: string) {
    return this.genericTrack('ViewContent', content_type, content_name);
  }
  successfulPayment(
    content_type: string,
    content_name: string,
    value: number,
    id: string
  ) {
    return this.genericTrack(
      'CompletePayment',
      content_type,
      content_name,
      value,
      id
    );
  }
  submitForm(content_type: string, content_name: string) {
    return this.genericTrack('SubmitForm', content_type, content_name);
  }

  genericTrack(
    event: string,
    content_type: string,
    content_name: string,
    value?: number,
    content_id?: string
  ) {
    const baseObject: tikTokBody = {
      event_source: 'web',
      event_source_id: 'CM1VNJ3C77UDNKHAJ8R0',
      data: [
        {
          event: event,
          event_time: 0,
          properties: { value: value || 0, currency: '' },
          contents: [
            { content_id: content_id || '', content_name, content_type },
          ],
        },
      ],
    };
    const headers = new HttpHeaders().set(
      'Access-Token',
      'e4a2f8b07ca4fcc69d24cd936ba60b1c28c8af19'
    );
    return this.PagesService.postNoBaseUrl(this.baseUrl, baseObject, headers);
  }
}
