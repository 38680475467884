import { AppComponent } from './app.component';

import { FrontpageComponent } from './pages/frontPage/frontpage/frontpage.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComingSoonComponent } from './shared/coming-soon/coming-soon.component';
import { AbroadComponent } from './pages/abroad/abroad.component';
import { NotFoundComponent } from './core/core/not-found/not-found.component';
import { ContactComponent } from './pages/faq/contact/contact.component';
import { DeductibleAmountsComponent } from './pages/faq/deductible-amounts/deductible-amounts.component';
import { DrivingTipsDetailsComponent } from './pages/faq/driving-tips-details/driving-tips-details.component';
import { DrivingTipsComponent } from './pages/faq/driving-tips/driving-tips.component';
import { FaqComponent } from './pages/faq/faq/faq.component';
import { InsuranceCoverageComponent } from './pages/faq/insurance-coverage/insurance-coverage.component';
import { PrivacyPolicyComponent } from './pages/faq/privacy-policy/privacy-policy.component';
import { SiteMapComponent } from './pages/faq/site-map/site-map.component';
import { TermsComponent } from './pages/faq/terms/terms.component';
import { BeAPartnerComponent } from './pages/about-us/be-a-partner/be-a-partner.component';

export const routes: Routes = [
  // {path:':language',children:[
  // {
  //   path: '',
  //   redirectTo: 'coming-soon',
  //   pathMatch: 'full',
  // },
  { path: '', component: FrontpageComponent, data: { breadcrumb: 'Home' } },
  {
    path: 'rent',
    loadChildren: () =>
      import('./pages/car-selection/car-selection.module').then(
        (m) => m.CarSelectionModule
      ),
    // data: { breadcrumb: 'Pick your car' },
  },
  {
    path: 'leasing',
    loadChildren: () =>
      import('./pages/leasing/leasing.module').then((m) => m.LeasingModule),
    data: { breadcrumb: 'Leasing' },
  },
  {
    path: 'oximata',
    loadChildren: () =>
      import('./pages/our-cars/our-cars.module').then((m) => m.OurCarsModule),
    // data: { breadcrumb: 'Oximata' },
  },
  {
    path: 'extra-services',
    loadChildren: () =>
      import('./pages/extra-services/extra-services.module').then(
        (m) => m.ExtraServicesModule
      ),
    // data: { breadcrumb: 'Extra services' },
  },
  {
    path: 'manage-reservation',
    loadChildren: () =>
      import('./pages/manage-reservation/manage-reservation.module').then(
        (m) => m.ManageReservationModule
      ),
    // data: { breadcrumb: 'Manage reservation' },
  },
  {
    path: 'drive-monthly',
    loadChildren: () =>
      import('./pages/mid-term-rent/mid-term-rent.module').then(
        (m) => m.MidTermRentModule
      ),
    // data: { breadcrumb: 'Mid term rent' },
  },
  {
    path: 'van',
    loadChildren: () =>
      import('./pages/van/van.module').then((m) => m.VanModule),
    // data: { breadcrumb: 'Van' },
  },

  {
    path: 'transfer-service',
    loadChildren: () =>
      import('./pages/transfer-service/transfer-service.module').then(
        (m) => m.TransferServiceModule
      ),
    // data: { breadcrumb: 'Transfer service' },
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
    // data: { breadcrumb: 'About us' },
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./pages/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'service',
    loadChildren: () =>
      import('./pages/service/service.module').then((m) => m.ServiceModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  // {
  //   path: 'extras',
  //   loadChildren: () =>
  //     import('./pages/faq/faq.module').then((m) => m.FaqModule),
  //   // data: { breadcrumb: 'Extras' },
  // },
  {
    path: 'enoikiasi-oximatos',
    loadChildren: () =>
      import('./pages/rent/rent.module').then((m) => m.RentModule),
    // data: { breadcrumb: 'Rent' },
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'blog',
    component: DrivingTipsComponent,
  },
  {
    path: 'sitemap',
    component: SiteMapComponent,
  },
  {
    path: 'deductible-amounts',
    component: DeductibleAmountsComponent,
  },
  {
    path: 'insurance-coverage',
    component: InsuranceCoverageComponent,
  },
  {
    path: 'blog/:id',
    component: DrivingTipsDetailsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'be-a-partner',
    component: BeAPartnerComponent,
  },
  {
    path: 'leasing-offers/opel-grandland',
    redirectTo: 'leasing/offers/opel-grandland',
  },
  {
    path: 'branches',
    redirectTo: 'about-us/branches',
  },
  {
    path: 'terms',
    redirectTo: 'extras/terms',
  },
  {
    path: 'faq',
    redirectTo: 'extras/faq',
  },
  {
    path: 'driving-tips',
    redirectTo: 'extras/blog',
  },
  {
    path: 'news',
    redirectTo: 'about-us/news',
  },
  {
    path: 'sitemap',
    redirectTo: 'about-us/sitemap',
  },
  {
    path: 'whoweare',
    redirectTo: 'about-us/whoweare',
  },
  // {
  //   path: 'enoikiasi-van-fortigou',
  //   redirectTo: 'van/enoikiasi-van-fortigou',
  // },
  {
    path: 'leitourgiki-misthosi-van',
    redirectTo: 'van/leitourgiki-misthosi-van',
  },
  {
    path: 'van-safety',
    redirectTo: 'van/van-safety',
  },
  {
    path: 'operating-leasing-autokinitou',
    redirectTo: 'leasing/operating-leasing-autokinitou',
  },
  {
    path: 'leasing-offers',
    redirectTo: 'leasing/leasing-offers',
  },

  {
    path: 'abroad',
    component: AbroadComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },

  // ]},
  // {path:'',redirectTo:'el',pathMatch:'full'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
