<div
  style="
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 100;
    top: 0;
  "
>
  <div class="w-100 bg-black mb-3">
    <nav
      class="top-nav myContainer1 d-flex navbar navbar-default navbar-fixed-top bg-black"
    >
      <div class="contactUs d-flex flex-row">
        <span class="text-white fw-light"
          >{{ "frontPage.pageTop.contact" | translate }} </span
        ><span class="text-white ps-1 fw-bold"
          ><a
            style="text-decoration: none"
            class="text-white"
            href="tel:+30 2310 472600"
            >2310 472600</a
          ></span
        >
      </div>
      <div class="top-link d-flex flex-row text-nowrap">
        <div class="d-flex me-2">
          <button class="nav-link">
            <a class="text-white" routerLink="/manage-reservation"
              >{{ "frontPage.pageTop.manage" | translate }}
            </a>
          </button>
        </div>
        <div class="d-flex me-2">
          <button class="nav-link">
            <a
              class="text-white"
              [routerLink]="getUser() ? '/user/profile' : '/user'"
              >{{ "frontPage.pageTop.login" | translate }}</a
            >
          </button>
        </div>
        <div class="d-flex me-2">
          <span class="nav-link text-white" (click)="toEnglish()">ΕΝ |</span>
          <span class="ms-1 nav-link text-white" (click)="toGreek()">ΕΛ</span>
        </div>
      </div>
    </nav>
  </div>

  <div class="myContainer1 d-flex flex-column">
    <nav
      class="main-nav justify-content-between navbar navbar-expand-xl flex-row"
    >
      <div class="nav-brand flex-column flex-xl-row" routerLink="">
        <img class="brand-img" src="assets/images/nav-brand.svg" alt="" />
      </div>
      <button
        style="margin-right: 1%"
        (click)="toggleShow()"
        class="navbar-toggler mt-3 bg-red"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span
          class="d-flex w-100"
          style="justify-content: center; align-items: center"
          >+</span
        >
      </button>

      <div
        class="navigation-items text-nowrap collapse navbar-collapse"
        [ngClass]="isCollapsed ? '' : 'show'"
        id="navbarSupportedContent"
      >
        <ul
          class="navbar-nav flex-column flex-xl-row fw-normal"
          style="z-index: 10"
        >
          <li class="nav-item">
            <a
              (click)="isCollapsed = !isCollapsed"
              routerLink=""
              class="nav-link"
              >{{ "frontPage.navbar.home" | translate }}</a
            >
          </li>
          <li class="nav-item rentLink" style="position: relative">
            <a class="nav-link" routerLink="/enoikiasi-oximatos"
              >{{ "frontPage.navbar.rent.title" | translate }}
            </a>
            <div class="dropDown" style="position: absolute; z-index: 10000">
              <a (click)="isCollapsed = !isCollapsed" routerLink="">{{
                "frontPage.navbar.rent.dropDown.rent" | translate
              }}</a>
              <mat-divider></mat-divider>
              <a (click)="isCollapsed = !isCollapsed" routerLink="oximata">{{
                "frontPage.navbar.rent.dropDown.vehicles" | translate
              }}</a>
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="extra-services"
                >{{
                  "frontPage.navbar.rent.dropDown.extraServices" | translate
                }}</a
              >
              <mat-divider *ngIf="lang !== 'en'"></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="drive-monthly/offers"
                >{{
                  "frontPage.navbar.rent.dropDown.midTermRent" | translate
                }}</a
              >
            </div>
          </li>
          <li class="nav-item leasing" style="position: relative">
            <a class="nav-link" routerLink="/leasing"
              ><div>{{ "frontPage.navbar.leasing.title" | translate }}</div>
            </a>
            <div
              class="dropDown"
              style="position: absolute; z-index: 10000; top: 100%"
            >
              <a (click)="isCollapsed = !isCollapsed" routerLink="leasing">{{
                "frontPage.navbar.leasing.dropDown.opLeasing" | translate
              }}</a>
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="leasing/leasing-offers"
                >{{ "frontPage.navbar.leasing.dropDown.offers" | translate }}</a
              >
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="leasing/form"
                >{{
                  "frontPage.navbar.leasing.dropDown.leasingRequest" | translate
                }}</a
              >
            </div>
          </li>
          <li class="nav-item rentVan">
            <a class="nav-link" routerLink="van">
              {{ "frontPage.navbar.rentVan.title" | translate }}
            </a>
            <div class="dropDown" style="position: absolute; z-index: 10000">
              <a (click)="isCollapsed = !isCollapsed" routerLink="van">{{
                "frontPage.navbar.rentVan.dropDown.dService" | translate
              }}</a>
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="van/leitourgiki-misthosi-van"
                >{{
                  "frontPage.navbar.rentVan.dropDown.opLeasing" | translate
                }}</a
              >
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="van/van-safety"
                >{{
                  "frontPage.navbar.rentVan.dropDown.vSafety" | translate
                }}</a
              >
            </div>
          </li>
          <li class="nav-item">
            <a
              (click)="isCollapsed = !isCollapsed"
              routerLink="be-a-partner"
              class="nav-link"
            >
              Be a partner
            </a>
          </li>
          <li class="nav-item">
            <a
              (click)="isCollapsed = !isCollapsed"
              routerLink="blog"
              class="nav-link"
            >
              Blog
            </a>
          </li>
          <li class="nav-item d-none">
            <a routerLink="transfer-service" class="nav-link">
              {{ "frontPage.navbar.transfer" | translate }}
            </a>
          </li>
          <li class="nav-item about-us">
            <a href="" routerLink="about-us" class="nav-link">
              {{ "frontPage.navbar.whoWeAre.title" | translate }}
            </a>
            <div class="dropDown" style="position: absolute; z-index: 10000">
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="about-us/branches"
                >{{
                  "frontPage.navbar.whoWeAre.dropDown.stores" | translate
                }}</a
              >
              <mat-divider></mat-divider>
              <a
                (click)="isCollapsed = !isCollapsed"
                routerLink="about-us/news"
                >{{ "frontPage.navbar.whoWeAre.dropDown.news" | translate }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="strip bg-red text-white w-100"></div>
  <div
    *ngIf="false"
    class="myContainer1 bg-red text-white p-2 my-2"
    style="z-index: -10; position: relative"
  >
    <div class="d-flex justify-content-center flex-column flex-md-row">
      <div style="font-size: 1.1rem" class="d-flex text-bold">
        Μπείτε στην σελίδα μας στο Instagram, πάρτε μέρος στον διαγωνισμό και 5
        τυχεροί θα κερδίσετε από 1 εκπτωτικό κουπόνι αξίας 30€/κάθε ένα για
        ενοικίαση αυτοκινήτου από την Drive.
      </div>
      <div class="ps-3">
        <a
          target="_blank"
          href="https://www.instagram.com/p/Cti6TBPqGrw/"
          class="text-dark text-sm text-white"
          >{{ "frontPage.offerStrip.link" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
