import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { PagesService } from '../pages.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FrontpageService {
  locationsArray: any = [];
  selectedCarsData: any;

  constructor(private service: PagesService, private route: ActivatedRoute) {}

  getFrontPageOffers(group: string, position: string) {
    return this.service
      .get<any[]>('offers')
      .pipe(
        map((item: any) =>
          item
            .filter((element: any) =>
              element?.displayLocations.includes(`${group}-${position}`)
            )
            .pop()
        )
      );
  }

  getLocation(path: any) {
    return this.service.get<any[]>(path).pipe(
      map(
        (res: any) =>
          res.map((res: any) => ({
            name: res.name,
            code: res.code,
            id: res._id,
          }))
        // res.forEach((element: any) => {
        //   console.log(element);
        //   this.locationsArray = this.locationsArray.concat({
        //     name: element.name,
        //     code: element.code,
        //     id: element._id,
        //   });
        // });
        // return this.locationsArray;
      )
    );
  }
  getFilters() {
    let res;
    this.route.queryParams.subscribe((x) => {
      res = x;
    });
    return res;
  }

  getCars(filter: any) {
    // console.log(filter);
    return this.service.get(`cars?filters=${filter}`).pipe(
      map((res: any) => {
        this.selectedCarsData = res.filter(
          (item: { xmlData: undefined }) => item.xmlData !== undefined
        );
        // console.log('carData',this.selectedCarsData);
        // sessionStorage.setItem('carData',JSON.stringify(this.selectedCarsData))
        return this.selectedCarsData;
      })
    );
  }
  getAllCars() {
    return this.service.get(`cars`).pipe(
      map((res: any) => {
        this.selectedCarsData = res.filter(
          (item: { xmlData: undefined }) => item.xmlData !== undefined
        );

        return this.selectedCarsData;
      })
    );
  }
}
