import { SeoService } from 'src/app/core/core/services/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
  constructor(private SeoService: SeoService) {}

  ngOnInit(): void {
    this.SeoService.setTitle('Not Found');
  }
}
