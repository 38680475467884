import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(private swUpdate: SwUpdate) {}
  checkForUpdatesAndReload() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe((evt) => {
        console.log('#pwa updates', evt);
        // Reload the page to update to the latest version.
        document.location.reload();
      });
  }
  ifCorruptReload() {
    this.swUpdate.unrecoverable.subscribe((event) => {
      document.location.reload();
    });
  }
}
