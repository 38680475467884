<div class="d-flex flex-column align-items-center">
  <!-- <mat-icon>tag_faces</mat-icon> -->

  <h5>{{ data.title }}</h5>
  <p>{{ data.description }}</p>
  <button
    mat-flat-button
    (click)="closeModal()"
    [routerLink]="data.route"
    class="bg-red text-white myButton"
  >
    Επιστροφή
  </button>
</div>
