import { FilteringComponent } from './filtering/filtering.component';
import { FrontReusableOfferComponent } from './front-reusable-offer/front-reusable-offer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsComponent } from './about-us/about-us.component';
import { environment } from 'src/environments/environment';
import { CarHeaderComponent } from './car-header/car-header.component';
import { CarStepperComponent } from './car-stepper/car-stepper.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CarCardComponent } from './car-card/car-card.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginatorComponent } from './paginator/paginator.component';
import { PricedEquipsComponent } from './priced-equips/priced-equips.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InsuranceComponent } from './Insurance/insurance.component';
import { MatRadioModule } from '@angular/material/radio';
import { AscendingPipe } from './pipes/ascending.pipe';
import { CarCardAnimatedComponent } from './car-card-animated/car-card-animated.component';
import { RouterModule } from '@angular/router';
import { CarOverviewComponent } from './car-overview/car-overview.component';
import { CarsCarouselComponent } from './cars-carousel/cars-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { LeasingFiltersPipe } from './pipes/leasing-filters.pipe';
import { ValidFiltersPipe } from './pipes/valid-filters.pipe';
import { ComingSoonModule } from './coming-soon/coming-soon.module';
import { AbroadModule } from '../pages/abroad/abroad.module';
import { CarFilteringComponent } from './car-filtering/car-filtering.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LeasingModule } from '../pages/leasing/leasing.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GalleryComponent } from './gallery/gallery.component';
import { BasicRadioButtonComponent } from './basic-radio-button/basic-radio-button.component';
import { GoogleMapsModule } from '@angular/google-maps';

// import { NgxPaypalComponent } from 'ngx-paypal';

@NgModule({
  declarations: [
    FrontReusableOfferComponent,
    AboutUsComponent,
    CarHeaderComponent,
    CarStepperComponent,
    CarCardComponent,
    FilteringComponent,
    PaginatorComponent,
    PricedEquipsComponent,
    InsuranceComponent,
    AscendingPipe,
    CarCardAnimatedComponent,
    CarOverviewComponent,
    CarsCarouselComponent,
    ValidFiltersPipe,
    CarFilteringComponent,
    LeasingFiltersPipe,
    GalleryComponent,
    BasicRadioButtonComponent,
  ],
  imports: [
    CommonModule,

    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatNativeDateModule,
    NgxPaginationModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    RouterModule,
    CarouselModule,
    TranslateModule,
    AbroadModule,
    CdkAccordionModule,
    MatTooltipModule,
    GoogleMapsModule,
  ],
  exports: [
    FrontReusableOfferComponent,
    AboutUsComponent,
    CarHeaderComponent,
    CarStepperComponent,
    CarCardComponent,
    FilteringComponent,
    PaginatorComponent,
    PricedEquipsComponent,
    InsuranceComponent,
    AscendingPipe,
    CarCardAnimatedComponent,
    CarOverviewComponent,
    CarsCarouselComponent,
    ValidFiltersPipe,
    CarFilteringComponent,
    LeasingFiltersPipe,
    GalleryComponent,
  ],
})
export class SharedModule {}
