import { BreadcrumbService } from './shared/utils/breadcrumb.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthService } from './core/core/services/auth.service';
import { PwaService } from './core/core/services/pwa.service';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BrowserService } from './core/core/services/browser.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'driveHellas';
  url!: string;
  env = environment;
  espa: any = [
    // {
    //   img: {
    //     en: '/assets/images/espa/etpa_english.jpg',
    //     el: '/assets/images/espa/etpa_greek.jpg',
    //   },
    //   link: {
    //     en: 'https://static.drive-hellas.com/images/uploads/improvement.pdf',
    //     el: 'https://static.drive-hellas.com/images/uploads/improvement.pdf',
    //   },
    // },
    {
      img: {
        en: '/assets/images/espa/epanek_english.jpg',
        el: '/assets/images/espa/epanek_greek.jpg',
      },
      link: {
        en: 'https://static.drive-hellas.com/images/uploads/espa-pdf-english.pdf',
        el: 'https://static.drive-hellas.com/images/uploads/espa-pdf.pdf',
      },
    },
    // {
    //   img: {
    //     en: '/assets/images/espa/drive-ee-logo.jpg',
    //     el: '/assets/images/espa/drive-ee-logo.jpg',
    //   },
    //   link: {
    //     en: 'https://static.drive-hellas.com/images/uploads/ee.pdf',
    //     el: 'https://static.drive-hellas.com/images/uploads/ee.pdf',
    //   },
    // },
  ];
  lang: 'el' | 'en' = 'el';
  breadcrumbs$: Observable<
    {
      url: string;
      label: any;
      active: boolean;
    }[]
  > = this.BreadcrumbService.breadcrumbs$.pipe(
    tap((breadcrumbs) => this.createBreadcrumbSchema(breadcrumbs))
  );
  typeof: any;
  // breadcrumbs$!: Observable<any>;
  constructor(
    private translateService: TranslateService,
    private BreadcrumbService: BreadcrumbService,
    private PwaService: PwaService,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private BrowserService: BrowserService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.translateService.onLangChange.subscribe((change) => {
      this.lang = change.lang as 'el' | 'en';
    });
  }

  // menuMode = 'overlay';

  ngOnInit() {
    this.updateCurrentRoute().subscribe();
    this.translateService.setDefaultLang(environment.defaultLanguage || 'el');
    this.translateService.use('el');
    this.translateService.addLangs(['en']);
    this.PwaService.checkForUpdatesAndReload();
    this.lang = this.translateService.currentLang as 'el' | 'en';
    const user = JSON.parse(
      this.BrowserService.getLocalStorage('currentUser') || '{}'
    );
    if (user) this.AuthService.initiateUser(user);
  }
  scrollTop(data: any) {
    this.BrowserService.scrollTo(0, 0);
  }

  updateCurrentRoute() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((event: any) => {
        this.url = event.url;
        this.BreadcrumbService.configureBreadcrumbs(event);
        // this.breadcrumbs$ = this.BreadcrumbService.se(event);
        // this.createBreadcrumbSchema();
      })
      // switchMap((event) => {
      // return  this.BreadcrumbService.configureBreadcrumbs(event);
      // })
    );
  }
  //todo add the next two functions to google schema service
  createBreadcrumbSchema(breadcrumbs: any) {
    if (!isPlatformBrowser(this.platformId)) return;

    const itemList = this.returnBreadCrumbItemList(breadcrumbs);
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
 {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": ${itemList}
    }
        `;

    this._renderer2.appendChild(this._document.body, script);
  }
  returnBreadCrumbItemList(breadcrumbs: any[]) {
    const formatted = breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name:
        typeof breadcrumb.label === 'object'
          ? breadcrumb.label.el
          : this.translateService.instant(breadcrumb.label),
      item: this.env.productionBaseUrl + breadcrumb.url,
    }));
    // console.log(formatted);
    return JSON.stringify(formatted);
  }
}
