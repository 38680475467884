import { PagesService } from 'src/app/pages/pages.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  couponSource = new BehaviorSubject<any>(null);
  coupon$ = this.couponSource;

  constructor(private PagesService: PagesService) {}

  getCoupon() {
    return this.couponSource.value;
  }
  getCouponId() {
    return this.couponSource.value._id;
  }
  setCoupon(coupon: any) {
    this.couponSource.next(coupon);
  }
  resetCoupon() {
    this.couponSource.next(null);
  }
  reduceCouponUseTimes() {
    const id = this.couponSource?.value?._id;
    if (!id) return of(0);

    return this.PagesService.put('coupons', id);
  }
  createCoupon(email: string) {
    return this.PagesService.post('coupons/newsletter', { email });
  }

  // getFromStorage() {
  //   return JSON.parse(
  //     localStorage.getItem('coupon')
  //       ? localStorage.getItem('coupon') || '{}'
  //       : '{}'
  //   );
  // }
  // setToStorage(data: object) {
  //   localStorage.setItem('coupon', JSON.stringify(data));
  //   return;
  // }
}
