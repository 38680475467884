<div class="myContainer">
  <ng-container *ngIf="vm$ | async as vm">
    <iframe class="frame" [src]="vm"></iframe>
  </ng-container>
  <!-- <div #cartrawler class="frame"></div> -->
</div>

<ng-container *ngIf="vmError$ | async as error">{{
  error | json
}}</ng-container>
