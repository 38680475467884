<div
  class="myContainer cont1 d-flex flex-row align-items-center {{ rotation }}"
>
  <div class="image-cont w-50">
    <img
      src="https://static.drive-hellas.com/images/v2/{{ imageUrl }}"
      class="w-100"
      alt=""
    />
  </div>
  <div
    class="text-cont d-flex flex-column justify-content-between w-50 {{
      textOrientation
    }} "
  >
    <span style="font-weight: 600; font-size: 3rem" class="pb-2">{{
      title[lang]
    }}</span>
    <span class="pb-4" style="font-size: 2rem; font-weight: 200">{{
      subtitle[lang]
    }}</span>
    <span class="pb-4 flex-grow-1" style="font-size: 1rem; opacity: 0.8">{{
      description[lang]
    }}</span>
    <a target="_blank" [href]="url">
      <button
        mat-flat-button
        style="border-radius: 1px; width: 12rem; height: 3.5rem"
        class="bg-red mb-2"
      >
        {{ "offers.button" | translate }}
      </button>
    </a>
  </div>
</div>
