import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getLocalStorage(key: string): string | null {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    }
    return null;
  }

  setLocalStorage(key: string, value: string): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  scrollTo(x: number, y: number, behavior: 'smooth' | 'auto' = 'auto'): void {
    if (this.isBrowser) {
      window.scrollTo({ top: y, behavior: behavior });
    }
  }

  getWindow(): Window | null {
    return this.isBrowser ? window : null;
  }

  removeLocalStorage(key: string): void {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    }
  }

  clearLocalStorage(): void {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }
}
