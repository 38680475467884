import { TranslateService } from '@ngx-translate/core';
import { FaqService } from './../faq.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deductible-amounts',
  templateUrl: './deductible-amounts.component.html',
  styleUrls: ['./deductible-amounts.component.css']
})
export class DeductibleAmountsComponent implements OnInit {
  data:any
  lang = 'el'
  constructor(private fService:FaqService,private translate:TranslateService) {
    this.translate.onLangChange.subscribe(x=>this.lang=x.lang)
  } 

  ngOnInit(): void {
    this.lang = this.translate.currentLang
    this.getData()
  }

  async getData(){
    this.data = await this.fService.getDeductible().toPromise()
    
  }

}
