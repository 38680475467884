import { SeoService } from 'src/app/core/core/services/seo.service';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FaqService } from '../faq.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BreadcrumbService } from 'src/app/shared/utils/breadcrumb.service';

@Component({
  selector: 'app-driving-tips-details',
  templateUrl: './driving-tips-details.component.html',
  styleUrls: ['./driving-tips-details.component.css'],
})
export class DrivingTipsDetailsComponent implements OnInit {
  data: any;
  lang = 'el';
  constructor(
    private fService: FaqService,
    private translate: TranslateService,
    private router: ActivatedRoute,
    private seo: SeoService,
    private _renderer2: Renderer2,
    private breadcrumbService: BreadcrumbService,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.translate.onLangChange.subscribe((x) => (this.lang = x.lang));
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.getData();
  }

  async getData() {
    let route = this.router.snapshot.params.id;
    // console.log(route);
    let temp: any = await this.fService.getDrivingData().toPromise();
    this.data = temp.find((x: any) => x.slug === route);
    this.seo.setTitle(this.data?.metaTitle || this.data.title[this.lang]);
    this.seo.setDescription(this.data?.metaDescription);
    this.createOrganizationSchema(this.data);
    this.breadcrumbService.setIdPageBreadcrumb({
      label: this.data?.title,
      slug: this.data?.slug,
    });
  }
  createOrganizationSchema(blog: any) {
    if (!isPlatformBrowser(this.platformId)) return;

    // console.log(blog);
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
              {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "${blog.title.el}",
      "image": ["https://static.drive-hellas.com/images/v2/${blog.images[0].filename}"],
      "datePublished":"${blog.createdAt}",
      "dateModified": "${blog.lastModified}",
      "author": [{
          "@type": "Organization",
          "name": "Drive Hellas",
          "url": "https://www.drive-hellas.com/about-us/whoweare"
        }]
          }
        `;

    this._renderer2.appendChild(this._document.body, script);
  }
}
