import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TiktokService } from './tiktok.service';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';

declare let fbq: Function;
declare let gtag: Function;
declare let ttq: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  TiktokService = inject(TiktokService);
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  private shouldTrack(): boolean {
    return isPlatformBrowser(this.platformId) && environment.production;
  }

  private logDebug(eventName: string, data: any): void {
    if (!environment.production) {
      console.log(`[Analytics Debug] ${eventName}:`, data);
    }
  }
  viewContent(data: {
    content_ids?: string[];
    content_category: string;
    content_name: string;
    content_type?: string;
    contents?: object[];
    currency?: string;
    value?: number;
  }) {
    this.logDebug('ViewContent', data);
    if (!this.shouldTrack()) return;

    fbq('track', 'ViewContent', { ...data });
    ttq.track('ViewContent', {
      contents: [
        {
          content_id: '',
          content_name: data.content_name,
          content_category: data.content_category,
          brand: '',
          quantity: 0,
          price: 0,
        },
      ],
    });
    gtag('event', 'page_view', {
      send_to: 'AW-750891237',
      user_id: data.content_name,
      value: data.value,
      items: data.contents,
    });
  }

  initiatesCheckout(data: {
    content_category: string;
    content_ids: string[];
    contents?: object[];
    currency?: string;
    num_items?: number;
    value?: number;
  }) {
    this.logDebug('InitiateCheckout', data);
    if (!this.shouldTrack()) return;
    fbq('track', 'InitiateCheckout', { ...data });
  }

  paymentInformation(data: {
    content_category: string;
    content_ids?: string[];
    contents: object[];
    currency?: string;
    value?: number;
  }) {
    this.logDebug('AddPaymentInfo', data);
    if (!this.shouldTrack()) return;
    fbq('track', 'AddPaymentInfo', { ...data });
  }

  successfulPurchase(data: {
    content_ids: string[];
    content_name?: string;
    content_type?: string;
    contents?: object[];
    currency: string;
    num_items?: number;
    value: number;
  }) {
    this.logDebug('Purchase', data);
    if (!this.shouldTrack()) return;
    fbq('track', 'Purchase', { ...data });
    gtag('event', 'purchase', {
      // eslint-disable-line
      value: data.value,
      currency: 'EUR',
      transaction_id: data.content_ids[0],
    });
    gtag('event', 'conversion', {
      send_to: 'AW-750891237/n_acCIPx15gBEOXhhuYC',
      value: data.value,
      currency: 'EUR',
      transaction_id: data.content_ids[0],
    });

    ttq.track('CompletePayment', {
      contents: [
        {
          content_id: data.content_ids[0],
          content_name: data.content_name,
          content_category: '',
          content_type: data.content_type || '',
          brand: '',
          quantity: 0,
          price: 0,
          value: data.value,
        },
      ],
    });
  }

  contactForm(data: {
    content_category: string;
    content_name: string;
    currency?: string;
    value?: number;
  }) {
    this.logDebug('contactForm', data);
    if (!this.shouldTrack()) return;
    fbq('track', 'Lead', { ...data });
    gtag('event', 'conversion', {
      send_to: 'AW-750891237/9FgoCMHz0pkBEOXhhuYC',
    });
    ttq.track('SubmitForm', {
      contents: [
        {
          content_id: '',
          content_name: data.content_name,
          content_category: data.content_category,
          content_type: 'Contact',
          brand: '',
          quantity: 0,
          price: 0,
          value: data.value,
        },
      ],
    });
  }
  leasingForm() {
    this.logDebug('LeasingForm', {});
    if (!this.shouldTrack()) return;
    gtag('event', 'conversion', {
      send_to: 'AW-750891237/U7JBCKvMhqABEOXhhuYC',
    });
    ttq.track('SubmitForm', {
      contents: [
        {
          content_id: '',
          content_name: '',
          content_category: 'Leasing form',
          content_type: 'Leasing',
          brand: '',
          quantity: 0,
          price: 0,
          value: 0,
        },
      ],
    });
  }
}
