<ng-container *ngIf="privacy$ | async as privacy">
  <div
    *ngIf="lang === 'el'"
    class="myContainer"
    [innerHtml]="privacy.bodyEl"
  ></div>
  <div
    *ngIf="lang === 'en'"
    class="myContainer"
    [innerHtml]="privacy.bodyEn"
  ></div>
</ng-container>
