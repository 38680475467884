<div class="myContainer" *ngIf="data">
  <div class="img">
    <img
      class="myImg"
      src="https://static.drive-hellas.com/images/v2/{{
        data.images[0].filename
      }}"
      [alt]="data.alt || 'driving tips details image'"
    />
  </div>
  <div class="cont">
    <h1 class="title text-white bg-red">{{ data.title[lang] }}</h1>
  </div>
  <div class="textCont">
    <div class="inner" [innerHtml]="data.body[lang]"></div>
  </div>
</div>
