<div class="myContainer">
  <form action="" [formGroup]="partnerForm">
    <h1 class="text-red">{{ title[lang] }}</h1>
    <!-- <h2 class="boldSubtitle text-red">Leasing</h2> -->
    <h5 class="text-bold sans-pro" [innerHTML]="description[lang]"></h5>

    <div class="d-flex flex-column flex-md-row formPanel">
      <div class="d-flex flex-column firstColumn">
        <div class="{{ data.class }}" *ngFor="let data of firstColumn">
          <mat-form-field appearance="outline" class="mat-form">
            <mat-label>{{ data.label[lang] }}</mat-label>
            <input
              matInput
              formControlName="{{ data.control }}"
              placeholder="{{ data.placeholder }}"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column secondColumn">
        <div class="{{ data.class }}" *ngFor="let data of secondColumn">
          <mat-form-field appearance="outline" class="mat-form">
            <mat-label>{{ data.label[lang] }}</mat-label>
            <input
              matInput
              formControlName="{{ data.control }}"
              placeholder="{{ data.placeholder }}"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column thirdColumn">
        <div class="{{ thirdColumnEquipment.class }}">
          <mat-form-field appearance="outline" class="mat-form">
            <mat-label>{{ thirdColumnEquipment.label[lang] }}</mat-label>
            <input
              matInput
              formControlName="{{ thirdColumnEquipment.control }}"
              placeholder="{{ thirdColumnEquipment.placeholder }}"
              required
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="strip"></div>
    <div class="d-flex flex-row" style="justify-content: flex-end">
      <re-captcha
        (resolved)="resolved($event)"
        siteKey="{{ captchaKey }}"
      ></re-captcha>
      <button
        mat-flat-button
        [ngClass]="partnerForm.invalid ? 'disabled' : ''"
        [disabled]="partnerForm.invalid"
        (click)="onSubmit(partnerForm.value)"
        class="bg-red price-check text-white"
      >
        {{ "leasing.form.button" | translate }}
      </button>
    </div>
  </form>
</div>
