import { PagesService } from 'src/app/pages/pages.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private PagesService: PagesService) {}

  subscribeToNewsletter(email: string) {
    return this.PagesService.post('email/subscribe', { email });
  }
}
