import { DomSanitizer } from '@angular/platform-browser';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter, ignoreElements, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-abroad',
  templateUrl: './abroad.component.html',
  styleUrls: ['./abroad.component.css'],
})
export class AbroadComponent implements OnInit {
  // @ViewChild('cartrawler') carTrawler!: ElementRef<HTMLElement>;
  vm$ = this.route.queryParams.pipe(
    map((params) => {
      const string = new URLSearchParams(params);
      const baseUrl = 'https://book.cartrawler.com/?';
      return this.DomSanitizer.bypassSecurityTrustResourceUrl(
        baseUrl + string.toString()
      );
    })
  );
  vmError$ = this.route.queryParams.pipe(
    ignoreElements(),
    catchError((err) => of(err))
  );
  CT: any = {
    ABE: {
      Settings: {
        clientID: '862412',
        recentSearches: {
          enabled: false,
        },
        theme: {
          primary: '#FF0023',
        },
      },
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private DomSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}
  // ngAfterViewInit(): void {
  //   console.log(this.carTrawler);
  //   this.carTrawler.nativeElement.setAttribute('ct-app', '');
  //   this.loadScript();
  // }

  loadScript() {
    // this.carTrawler.Settings.version = '5.0';
    const cts = document.createElement('script');
    cts.type = 'text/javascript';
    cts.async = true;
    cts.src =
      '//ajaxgeo.cartrawler.com/abe' +
      this.CT.ABE.Settings.version +
      '/ct_loader.js?' +
      new Date().getTime(); // eslint-disable-line
    const s = document.getElementsByTagName('script')[0];
    console.log(s);
    s.parentNode?.insertBefore(cts, s);
  }
}
