import { CAR } from '../../core/core/models/car';
import { OrderService } from './order.service';
import { PagesService } from 'src/app/pages/pages.service';
import { Injectable } from '@angular/core';
import { FrontpageService } from '../frontPage/frontpage.service';
import { BehaviorSubject } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { BrowserService } from 'src/app/core/core/services/browser.service';
@Injectable({
  providedIn: 'root',
})
export class CarService {
  carSource = new BehaviorSubject<CAR | null>(null);
  car$ = this.carSource.asObservable();
  // .pipe(
  //   tap((data) => {
  //     console.log(data);
  //   })
  // );
  constructor(
    private frontService: FrontpageService,
    private PagesService: PagesService,
    private OrderService: OrderService,
    private BrowserService: BrowserService
  ) {}
  carFilters = this.frontService.selectedCarsData;

  getCarFilter() {
    return this.carFilters;
  }
  createReservation(body: any) {
    return this.PagesService.post('reservations', body);
  }
  getReservationById(id: any) {
    return this.PagesService.get(`reservations/${id}`);
  }
  createDigest(body: any) {
    return this.PagesService.post('alphabank/digest', body);
  }
  // createTempReservation(body: object) {
  //   return this.PagesService.post('tempReservations', body);
  // }
  createTempReservation(body: object) {
    return this.PagesService.post('reservations/pseudo/initial', body);
  }
  updateCar(car: CAR) {
    let currentCar = this.carSource.value;
    if (!currentCar) return;
    currentCar = { ...currentCar, selectedCar: car };
    this.setToStorage(currentCar);
  }
  updateInsurance(insurance: any) {
    let reservationObject = this.getFromStorage();
    reservationObject.carData.equipment =
      reservationObject.carData.equipment.filter((x: any) => x.type !== 11);

    if (insurance.price > 0) {
      reservationObject.carData.equipment.push({
        type: 11,
        amount: insurance.price * insurance.days,
        description: 'Super SCDW',
        quantity: 1,
      });
    }
    this.setToStorage(reservationObject);
    return reservationObject.carData.equipment;
  }
  updateOutOfHours(cost: number) {
    // console.log(cost);
    const reservationObject = this.getFromStorage();
    if (!cost) reservationObject.carData.outOfHours = '';
    if (cost)
      reservationObject.carData.outOfHours = {
        description: 'Out of Hours',
        amount: cost,
      };
    // console.log('%%$$', reservationObject);
    this.setToStorage(reservationObject);
  }

  initReservationObject(station: any, filter: any) {
    const { carData } = this.getFromStorage();
    let tempReservationObject = {
      carData: {
        carCode: '',
        carPrice: 0,
        equipment: [],
        filter,
        reference: {},
        totalPrice: 0,
        vehicleCharges: [],
        outOfHours: carData?.outOfHours ? carData?.outOfHours : '',
      },
      station,
      locale: '',
      prePaymentEmail: false,
    };
    this.setToStorage(tempReservationObject);
  }

  getFromStorage() {
    return JSON.parse(
      this.BrowserService.getLocalStorage('reservationObject')
        ? this.BrowserService.getLocalStorage('reservationObject') || '{}'
        : '{}'
    );
  }
  setToStorage(data: object) {
    this.BrowserService.setLocalStorage(
      'reservationObject',
      JSON.stringify(data)
    );
    this.OrderService.updateOrder(data);
    return;
  }
}
