import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private MatDialog: MatDialog) {}

  openDialog(
    data: { title: string; description: string; route: string } = {
      title: 'Επιτυχής υποβολή αίτησης',
      description: '',
      route: '',
    }
  ) {
    this.MatDialog.open(ModalComponent, { disableClose: true, data });
  }
  closeDialog() {
    this.MatDialog.closeAll();
  }
}
