<div
  class="myContainerFaqs d-flex flex-column flex-md-row justify-content-between"
>
  <div class="leftContainer d-flex flex-column col-12 col-md-3 mb-3">
    <div class="d-flex flex-column myBorder p-2 mb-2">
      <h3>Category</h3>
      <p
        class="fw-semi-bold categories"
        *ngFor="let item of categories"
        (click)="pickFaq(item.filter)"
      >
        <span>&#62;</span> {{ item.title }}
      </p>
    </div>
    <div class="d-flex flex-column myBorder p-2">
      <H3>Helping Center</H3>
      <p>Do you have a question?</p>
      <p>Ask us!</p>
      <p>
        HEADQUARTERS
        <br />
        7,5 klm. of Road Thessaloniki-Moudania
        <br />
        P.C. 570 01 Thessaloniki Greece
        <br />
        <a style="text-decoration: none" href="tel:+30 2310 472600"
          >2310 472600</a
        >

        <br />
        rentacar@drive-hellas.gr
      </p>
    </div>
  </div>
  <div class="rightContainer d-flex flex-column col-12 col-md-8">
    <ng-container *ngIf="faqs$ | async as faq">
      <cdk-accordion class="example-accordion">
        <cdk-accordion-item
          *ngFor="let item of faq; let index = index"
          #accordionItem="cdkAccordionItem"
          class="example-accordion-item"
          role="button"
          tabindex="0"
          [attr.id]="'accordion-header-' + index"
          [attr.aria-expanded]="accordionItem.expanded"
          [attr.aria-controls]="'accordion-body-' + index"
        >
          <div
            class="example-accordion-item-header d-flex myBorder p-2 mb-2"
            (click)="accordionItem.toggle()"
          >
            <span class="fw-semi-bold">{{ item.question[lang] }}</span>
            <div *ngIf="!accordionItem.expanded">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
            <div *ngIf="accordionItem.expanded">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </div>
          </div>

          <div
            class="example-accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index"
            [innerHtml]="item.answer[lang]"
          ></div>
        </cdk-accordion-item>
      </cdk-accordion>
    </ng-container>
  </div>
</div>
