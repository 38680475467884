<div class="myContainer d-flex flex-column flex-lg-row" style="margin-top: 5%">
  <div class="bg-red" style="width: 0.5rem; margin-right: 3%"></div>
  <div
    class="d-flex flex-column text-cont"
    style="width: 46.5%; padding-right: 1%"
  >
    <span
      *ngIf="aboutUsTitle"
      [innerHtml]="aboutUsTitle[lang]"
      class="text-red"
      style="font-size: 3.5rem; font-weight: 600"
    ></span>
    <span style="font-size: 1.8rem; font-weight: 200" class="text-black">{{
      "frontPage.aboutUs.rent" | translate
    }}</span>
    <span style="font-size: 4rem; font-weight: 200" class="text-black1">{{
      "frontPage.aboutUs.title" | translate
    }}</span>
    <span
      *ngIf="aboutUsContext"
      style="opacity: 0.8"
      [innerHtml]="aboutUsContext[lang]"
    ></span>
  </div>
  <div #mapContainer id="map" class="map-cont"></div>
  <!-- <div style="width: 46.5%">
    <google-map height="500px" width="100%" [options]="options">
      <map-marker
        *ngFor="let item of storesArray; let i = index"
        [position]="item.marker"
        [icon]="icon"
        (mous)="toggleMarkerInfo(i, true)"
        (mapMouseout)="toggleMarkerInfo(i, false)"
      >
        <map-info-window (closeclick)="(true)"
          ><h4>{{ item.name }}</h4>
          <p [innerHtml]="item.descr"></p
        ></map-info-window>
      </map-marker>
    </google-map>
  </div> -->

  <!-- <div class="w-50 map-cont" style="width: 50%">
    <agm-map
      [latitude]="latitude"
      [longitude]="longitude"
      [styles]="styles"
      [zoom]="zoom"
    >
      <agm-marker
        *ngFor="let item of storesArray; let i = index"
        [latitude]="item.lat"
        [longitude]="item.lng"
        [iconUrl]="icon"
        (mouseOver)="toggleMarkerInfo(i, true)"
        (mouseOut)="toggleMarkerInfo(i, false)"
      >
        <agm-info-window [isOpen]="item.display"
          ><h4>{{ item.name }}</h4>
          <p [innerHtml]="item.descr"></p
        ></agm-info-window>
      </agm-marker>
    </agm-map>
  </div> -->
</div>
<div class="myContainer">
  <div
    class="strip-cont d-flex flex-column flex-lg-row bg-black justify-content-between"
    style="padding: 2%; margin-top: 5%; margin-bottom: 5%"
  >
    <div class="d-flex flex-column">
      <span
        class="text-white slogan"
        style="font-size: 2rem; margin-bottom: 1rem"
        >{{ "frontPage.aboutUs.rent" | translate }}</span
      >
      <!-- <span
        class="text-white name-bold"
        style="font-weight: 600; font-size: 4rem"
        >{{ "frontPage.aboutUs.title" | translate }}</span
      > -->
    </div>
    <div
      class="d-flex flex-row"
      style="justify-content: center; align-content: center"
    >
      <button
        routerLink="oximata"
        mat-flat-button
        class="myBtn bg-white"
        style="
          border-radius: 1px;
          width: 15rem;
          margin-right: 5%;
          height: 5rem;
          font-weight: 600;
          font-size: 1.2rem;
          align-self: center;
        "
      >
        {{ "frontPage.aboutUs.vehicles" | translate }}
      </button>
      <button
        (click)="returnTop()"
        mat-flat-button
        class="bg-red myBtn"
        style="
          border-radius: 1px;
          width: 15rem;
          height: 5rem;
          font-weight: 600;
          font-size: 1.2rem;
          align-self: center;
        "
      >
        {{ "frontPage.aboutUs.reservation" | translate }}
      </button>
    </div>
  </div>
</div>
