import { AuthService } from './../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { SessionLimiterService } from 'src/app/shared/utils/session-limiter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  lang = 'el';

  constructor(
    public translate: TranslateService,
    private AuthService: AuthService
  ) {
    this.translate.onLangChange.subscribe((data) => {
      this.lang = data.lang;
    });
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }
  toggleShow() {
    this.isCollapsed = !this.isCollapsed;
  }
  toEnglish() {
    this.translate.use('en');
  }
  toGreek() {
    this.translate.use('el');
  }
  getUser() {
    return this.AuthService.currentUserValue?.user;
  }
}
