import { map } from 'rxjs/operators';
import { PagesService } from './../pages.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private pService: PagesService) {}

  getDeductible() {
    return this.pService.get('/pages/name/deductible-amounts').pipe(
      map((data: any) => {
        return data[0];
      })
    );
  }

  getInsurance() {
    return this.pService.get('/pages/name/insurance-coverage').pipe(
      map((data: any) => {
        return data[0];
      })
    );
  }

  getDrivingTitle() {
    return this.pService.get('categories/slug/driving-tips').pipe(
      map((data: any) => {
        return data[0];
      })
    );
  }

  getDrivingData() {
    return this.pService
      .get(
        '/posts?field=lastModified&order=DESC&filters={%22category%22:{%22$in%22:[%225b90d9d2e38280393307889f%22]}}'
      )
      .pipe(map((posts: any) => posts.filter((post: any) => post.enabled)));
  }

  getTerms() {
    return this.pService.get('/pages/name/terms').pipe(
      map((data: any) => {
        return data[0];
      })
    );
  }

  getFaq(filters: any) {
    return this.pService.get(
      `/faqs?field=createdAt&order=ASC&filters=${filters}`
    );
  }
  getPolicy() {
    return this.pService
      .get('pages/name/privacy-policy')
      .pipe(map((policy: any) => policy.pop()));
  }
  sendContactEmail(body: object) {
    return this.pService.post('email', body);
  }
}
