import { CouponService } from './../../../pages/car-selection/coupon.service';
import { CoreService } from './../services/core.service';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  emailControl = new FormControl('', Validators.required);
  thisYear = new Date().getFullYear();
  constructor(
    private CoreService: CoreService,
    private fb: FormBuilder,
    private CouponService: CouponService
  ) {}

  ngOnInit(): void {}

  submitEmail(email: FormControl) {
    if (email.invalid) return;
    this.CoreService.subscribeToNewsletter(email.value).subscribe((res) => {
      this.CouponService.createCoupon(email.value).subscribe((res: any) => {
        window.alert(res.message);
      });
      this.emailControl.reset();
    });
  }
}
