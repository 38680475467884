import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit {
  data: any;
  filters: any;
  categories = [
    { title: 'RENT A CAR', filter: 'rent' },
    { title: 'CAR PICK UP AND DROPOFF', filter: 'pickupdropoff' },
    { title: 'CAR USAGE', filter: 'usage' },
    { title: 'LEASING USAGE', filter: 'ussageleasing' },
  ];
  obj: any = {
    category: 'rent',
  };
  lang = 'el';
  faqs$: Observable<any> = this.fService.getFaq(JSON.stringify(this.obj));
  constructor(
    private fService: FaqService,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((x) => (this.lang = x.lang));
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }

  pickFaq(category: string) {
    this.obj = { ...this.obj, category };
    this.faqs$ = this.fService.getFaq(JSON.stringify(this.obj));
  }
}
