import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-driving-tips',
  templateUrl: './driving-tips.component.html',
  styleUrls: ['./driving-tips.component.css'],
})
export class DrivingTipsComponent implements OnInit {
  data: any;
  title: any;
  lang = 'el';
  constructor(
    private fService: FaqService,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((x) => (this.lang = x.lang));
  }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.getTitle();
    this.getData();
  }

  async getTitle() {
    this.title = await this.fService.getDrivingTitle().toPromise();
  }
  async getData() {
    this.data = await this.fService.getDrivingData().toPromise();
  }
  getImageUrl(img: string): string {
    return `https://static.drive-hellas.com/images/v2/${img}`;
  }
}
